import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleList: SystemStyleFunction = (props) => {
    return {
        bg: mode('#fff', 'gray.700')(props),
        boxShadow: mode('2xl', 'dark-lg')(props),
        minW: '3xs',
        py: '2',
        zIndex: 'dropdown',
        borderRadius: 'md',
        borderWidth: 0,
    };
};

const baseStyleItem: SystemStyleFunction = (props) => {
    return {
        py: '3',
        px: '8',
        borderRadius: '0px',
        transitionProperty: 'background',
        transitionDuration: 'ultra-fast',
        transitionTimingFunction: 'ease-in',
        a: {
            // Bootstrap override - this can go when bootstrap has been removed
            textDecoration: 'none',
            borderRadius: '0px',
            borderWidth: 0,
            color: 'gray.900',
        },
        _hover: {
            textDecoration: 'none',
            bg: 'gray.50',
            color: 'gray.900',
        },
        _focus: {
            bg: mode('gray.50', 'whiteAlpha.50')(props),
        },
        _active: {
            bg: mode('gray.100', 'whiteAlpha.100')(props),
        },
        _expanded: {
            bg: mode('gray.50', 'whiteAlpha.50')(props),
        },
        _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
        },
    };
};

const baseStyleGroupTitle: SystemStyleObject = {
    mx: 4,
    my: 2,
    fontWeight: 'semibold',
    fontSize: 'sm',
};

const baseStyleCommand: SystemStyleObject = {
    opacity: 0.6,
};

const baseStyleDivider: SystemStyleObject = {
    border: 0,
    borderBottom: '1px solid',
    borderColor: 'inherit',
    my: '0.5rem',
    opacity: 0.6,
};

const baseStyleButton: SystemStyleObject = {
    transitionProperty: 'common',
    transitionDuration: 'normal',
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    button: baseStyleButton,
    list: baseStyleList(props),
    item: baseStyleItem(props),
    groupTitle: baseStyleGroupTitle,
    command: baseStyleCommand,
    divider: baseStyleDivider,
});

export const Menu = {
    parts: parts.keys,
    baseStyle,
};
