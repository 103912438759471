export enum folderTypeNames {
    ROOT = 'Root',
    DRAFT = 'Draft',
    INBOX = 'Inbox',
    SENT = 'Sent',
    UNREAD = 'Unread',
    TRASH = 'Trash',
    MORE = 'MoreFolders',
    CUSTOM = 'Custom',
}
