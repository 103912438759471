// Original source https://github.com/atulmy/gql-query-builder
// A simple helper function to generate GraphQL queries using plain JavaScript Objects (JSON).

type Fields = Array<string | object>;

interface IQueryOptions {
    operation: string /* Operation name */;
    fields?: Fields /* Selection of fields to be returned by the operation */;
    variables?: any /* Any variables for the operation */;
}

interface IQueryBuilderOptions extends IQueryOptions {
    arguments?: object /* Arguments for operation */;
}

enum OperationType {
    Mutation = 'mutation',
    Query = 'query',
}

function query(options: IQueryBuilderOptions | IQueryBuilderOptions[]) {
    if (Array.isArray(options)) {
        return queryOperation(options.map((o) => buildQueryOptions(o)));
    }

    return queryOperation(buildQueryOptions(options));
}

function buildQueryOptions(queryBuilderOptions: IQueryBuilderOptions) {
    return {
        fields: queryBuilderOptions.fields,
        variables: queryBuilderOptions.variables,
        operation: queryBuilderOptions.arguments
            ? `${queryBuilderOptions.operation}(${buildArguments(queryBuilderOptions.arguments)})`
            : queryBuilderOptions.operation,
    };
}

function buildArguments(obj: object) {
    const keys = Object.keys(obj).filter((key: string) => includeArgument(obj[key]));
    const args = new Array<string>();
    keys.forEach((key) => {
        if (typeof obj[key] === 'object') {
            args.push(`${key}:{${buildArguments(obj[key])}}`);
        } else {
            const value = typeof obj[key] === 'string' ? JSON.stringify(obj[key]) : obj[key];
            args.push(`${key}:${value}`);
        }
    });
    return args.join(' ');
}

function includeArgument(obj: any): boolean {
    if (obj === undefined || obj === null) {
        return false;
    }

    if (typeof obj !== 'object') {
        return true;
    }

    return Object.keys(obj).filter((key: string) => includeArgument(obj[key])).length > 0;
}

function queryOperation(options: IQueryOptions | IQueryOptions[]) {
    if (Array.isArray(options)) {
        return queriesBuilder(options);
    }

    return queryBuilder(options);
}

function queryBuilder({ operation, fields = [], variables = {} }: IQueryOptions) {
    return operationWrapperTemplate(
        OperationType.Query,
        variables,
        operationTemplate({ variables, operation, fields })
    );
}

function queriesBuilder(queries: IQueryOptions[]) {
    return operationWrapperTemplate(
        OperationType.Query,
        resolveVariables(queries),
        queries.map(operationTemplate).join('\n  ')
    );
}

function mutationOperation(options: IQueryOptions | IQueryOptions[]) {
    if (Array.isArray(options)) {
        return mutationsBuilder(options);
    }

    return mutationBuilder(options);
}

function mutationBuilder({ operation, fields = [], variables = {} }: IQueryOptions) {
    return operationWrapperTemplate(
        OperationType.Mutation,
        variables,
        operationTemplate({ variables, operation, fields })
    );
}

function mutationsBuilder(mutations: IQueryOptions[]) {
    return operationWrapperTemplate(
        OperationType.Mutation,
        resolveVariables(mutations),
        mutations.map(operationTemplate).join('\n  ')
    );
}

function resolveVariables(operations: IQueryOptions[]): any {
    let ret: any = {};

    operations.forEach(({ variables }) => {
        ret = { ...ret, ...variables };
    });

    return ret;
}

function operationWrapperTemplate(type: OperationType, variables: any, content: string) {
    return {
        query: `${type} ${queryDataArgumentAndTypeMap(variables)} {
  ${content}
}`,
        variables: queryVariablesMap(variables),
    };
}

function operationTemplate({ variables, operation, fields }: IQueryOptions) {
    return `${operation} ${queryDataNameAndArgumentMap(variables)} {
    ${queryFieldsMap(fields)}
  }`;
}

// Convert object to name and argument map. eg: (id: $id)
function queryDataNameAndArgumentMap(variables?: any) {
    return variables && Object.keys(variables).length
        ? `(${Object.keys(variables).reduce(
              (dataString, key, i) => `${dataString}${i !== 0 ? ', ' : ''}${key}: $${key}`,
              ''
          )})`
        : '';
}

// Convert object to argument and type map. eg: ($id: Int)
function queryDataArgumentAndTypeMap(variables: any): string {
    return Object.keys(variables).length
        ? `(${Object.keys(variables).reduce(
              (dataString, key, i) => `${dataString}${i !== 0 ? ', ' : ''}$${key}: ${queryDataType(variables[key])}`,
              ''
          )})`
        : '';
}

// Fields selection map. eg: { id, name }
function queryFieldsMap(fields?: Fields): string {
    return fields
        ? fields
              .map((field) =>
                  typeof field === 'object'
                      ? `${Object.keys(field)[0]} { ${queryFieldsMap(Object.values(field)[0])} }`
                      : `${field}`
              )
              .join(', ')
        : '';
}

// Variables map. eg: { "id": 1, "name": "Jon Doe" }
function queryVariablesMap(variables: any) {
    const variablesMapped: { [key: string]: unknown } = {};

    Object.keys(variables).forEach((key) => {
        variablesMapped[key] = typeof variables[key] === 'object' ? variables[key].value : variables[key];
    });

    return variablesMapped;
}

// Get GraphQL equivalent type of data passed (String, Int, Float, Boolean)
function queryDataType(variable: any) {
    let type = 'String';

    const value = typeof variable === 'object' ? variable.value : variable;

    if (variable.type !== undefined) {
        type = variable.type;
    } else {
        switch (typeof value) {
            case 'object':
                type = 'Object';
                break;

            case 'boolean':
                type = 'Boolean';
                break;

            case 'number':
                type = value % 1 === 0 ? 'Int' : 'Float';
                break;
        }
    }

    if (typeof variable === 'object' && variable.required) {
        type += '!';
    }

    return type;
}

export { mutationOperation as mutation, query };
