export enum tableViewTypeEnum {
    AdminPortalList,
    StripedList,
}

export enum tableViewTextAlignEnum {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}
