import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { mediaSize } from '../../enums';

const appReadoutsSlice = createSlice({
    name: 'appReadouts',
    initialState: {
        mediaSize: null,
        scrollTop: undefined,
        isSystemErrorMounted: false,
        isConfigLoaded: false,
        requestState: false,
        isAnonymousPageMounted: false,
        routeFriendlyName: null,
    },
    reducers: {
        setMediaSize: (draft, action: PayloadAction<mediaSize>) => {
            const { payload } = action;
            draft.mediaSize = payload;
        },

        setConfigLoaded: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.isConfigLoaded = payload;
        },
        setScrollTop: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            draft.scrollTop = payload;
        },
        systemErrorMounted: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.isSystemErrorMounted = payload;
        },
        setRequestState: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.requestState = payload;
        },
        anonymousPageMounted: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.isAnonymousPageMounted = payload;
        },
        setRouteFriendlyName: (draft, action: PayloadAction<string>) => {
            const { payload } = action;
            draft.routeFriendlyName = payload;
        },
    },
});

export const { reducer: appReadoutsReducer } = appReadoutsSlice;
export const {
    anonymousPageMounted,
    setConfigLoaded,
    setMediaSize,
    setRequestState,
    setRouteFriendlyName,
    setScrollTop,
    systemErrorMounted,
} = appReadoutsSlice.actions;
export type IAppReadoutsState = ReturnType<typeof appReadoutsReducer>;
