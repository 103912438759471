import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';
import type { ISender, IAllSenders, ISenderBranding, ISenderCreate, ISenderUpdate } from '../../../../interfaces';

const baseUrl = staticConfig.apiBaseUrl;

interface SendersQueryParams {
    organizationId: number;
    active?: boolean;
}

interface SenderDetailsQueryParams {
    organizationId: number;
    senderId: number;
}

interface SendersBrandingQueryParams {
    organizationId: number;
    senderId: number;
    locale?: string;
}

interface SenderDetailsQueryParams {
    organizationId: number;
    senderId: number;
}

interface SendersBrandingQueryParams {
    organizationId: number;
    senderId: number;
    locale?: string;
}

const tagTypes = ['Senders'];

export const sendersApi = createApi({
    reducerPath: 'sendersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getSender: builder.query<IAllSenders[], SendersQueryParams>({
            query: ({ organizationId, active = false }) => {
                const activeFilter = active ? `active=${active}` : '';
                return `/organization/${organizationId}/sender?${activeFilter}&$sort=name&pageSize=${50}`;
            },
            providesTags: tagTypes,
        }),
        getSenderById: builder.query<ISender, SenderDetailsQueryParams>({
            query: ({ organizationId, senderId }) => {
                return `/organization/${organizationId}/sender/${senderId}`;
            },
            providesTags: tagTypes,
        }),
        getSenderDetails: builder.query<ISender, SenderDetailsQueryParams>({
            query: ({ organizationId, senderId }) => {
                return `organization/${organizationId}/sender/${senderId}/details`;
            },
            providesTags: tagTypes,
        }),
        createSender: builder.mutation({
            query: (sender: ISenderCreate) => ({
                url: `organization/${sender.organizationId}/sender`,
                method: 'POST',
                body: sender,
            }),
            invalidatesTags: tagTypes,
        }),
        updateSender: builder.mutation({
            query: (sender: ISenderUpdate) => ({
                url: `organization/${sender.organizationId}/sender/${sender.id}`,
                method: 'PUT',
                body: sender,
            }),
            invalidatesTags: tagTypes,
        }),
        getSenderBranding: builder.query<ISenderBranding, SendersBrandingQueryParams>({
            query: (branding) => {
                return `organization/${branding.organizationId}/sender/${branding.senderId}/branding/${branding.locale}`;
            },
            providesTags: tagTypes,
        }),
        createOrUpdateSenderBranding: builder.mutation({
            query: (branding: ISenderBranding) => ({
                url: `organization/${branding.organizationId}/sender/${branding.senderId}/branding/${branding.locale}`,
                method: 'PUT',
                body: branding,
            }),
            invalidatesTags: tagTypes,
        }),
    }),
});

export const {
    useGetSenderQuery,
    useLazyGetSenderQuery,
    useGetSenderByIdQuery,
    useGetSenderDetailsQuery,
    useCreateSenderMutation,
    useUpdateSenderMutation,
    useCreateOrUpdateSenderBrandingMutation,
    useGetSenderBrandingQuery,
} = sendersApi;
