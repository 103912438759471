import React from 'react';
import { HTMLChakraProps, forwardRef, chakra, Tooltip, As, useStyles, Icon, CSSObject } from '@chakra-ui/react';
import { useNavigationContext } from './Navigation';

interface NavigationItemProps extends HTMLChakraProps<'button'> {
    icon?: As<any>;
    isActive?: boolean;
}

/**
 * Standard navigation Item
 */
export const NavigationItem = forwardRef<NavigationItemProps, 'button'>(
    ({ icon, title, isActive, children, ...props }, ref) => {
        const styles = useStyles();
        const { isCollapsed } = useNavigationContext();

        const buttonStyles: CSSObject = {
            ...styles.item,
            ...(isCollapsed ? { justifyContent: 'center' } : {}),
        };

        return (
            <Tooltip openDelay={500} label={title} placement="right">
                <chakra.button ref={ref} __css={buttonStyles} {...props} data-state={isActive ? 'active' : ''}>
                    {icon && <Icon as={icon} fontSize="xl" />}
                    {!isCollapsed && <chakra.span ms="3">{children}</chakra.span>}
                </chakra.button>
            </Tooltip>
        );
    }
);
