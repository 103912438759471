export enum menuBarButtonType {
    BACK = 'BACK',
    SELECT_ALL = 'SELECT_ALL',
    MAIL_REPLY = 'MAIL_REPLY',
    MAIL_FORWARD = 'MAIL_FORWARD',
    MAIL_ARCHIVE = 'MAIL_ARCHIVE',
    MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',
    MAIL_DELETE = 'MAIL_DELETE',
    MORE_ACTIONS = 'MORE_ACTIONS',
    MAIL_PRINT = 'MAIL_PRINT',
    CLOSE = 'CLOSE',
    SAVE = 'SAVE',
    ADD_EDIT_NOTE = 'ADD_EDIT_NOTE',
    MORE_INFORMATION = 'MORE_INFORMATION',
    FOLDER_ACTION = 'FOLDER_ACTION',
    MARK_AS_READ = 'MARK_AS_READ',
    MARK_AS_UNREAD = 'MARK_AS_UNREAD',
    FORWARD_SEND = 'FORWARD_SEND',
    FORWARD_DISCARD = 'FORWARD_DISCARD',
    REPLY_SEND = 'REPLY_SEND',
    REPLY_DISCARD = 'REPLY_DISCARD',
    ATTACH_FILE = 'ATTACH_FILE',
    SORT_FILTER = 'SORT_FILTER',
    BACK_TO_MAILBOX = 'BACK_TO_MAILBOX',
}

export enum selectAllItems {
    NONE = null,
    CHECK_ALL = 0,
    CHECK_UNREAD = 1,
    CHECK_READ = 2,
    UNCHECK_ALL = 3,
}

export enum moveToFolderItems {
    STATIC,
    CUSTOM_FOLDERS,
    MOVE_MAILS_TO_OTHER_FOLDER,
    MOVE_MAILS_TO_NEW_FOLDER,
}

export enum moreActionsItems {
    VIEW_CORRESPONDENCE,
    DOWNLOAD,
    TEXT_PRINT,
    RENAME_MAILS,
    REPLY,
    FORWARD,
    ARCHIVE,
    ADD_NOTE,
    EDIT_NOTE,
    INFORMATION,
}

export enum menuBarViewType {
    list = 0,
    previewDocument = 1,
    forwardDocument = 2,
    replyDocument = 3,
}
