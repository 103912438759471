// Store
import store from '../redux/store';
import { logoutUser } from '../redux';
// Interfaces
import {
    IAccessTokenRequest,
    IRefreshTokenRequest,
    ITokenResponse,
    ITokenUserInfo,
    IUserStatus,
} from '../interfaces/login.interfaces';
import * as api from '../middleware/api.middleware';
import { IMiddlewareOptions } from '../middleware/interfaces';
// Config
import { staticConfig } from '../config/static';
// Helpers
import { stringify } from '../helpers/queryString';
import { setTokenExpiration, loginRedirect, clearAuthStorage } from '../helpers/auth';
// Enums
import { hybridClientIds } from '../enums/portal';

const serviceUrl = '/loginservice';

const authenticationRequest = (data: any) => {
    const query: any = stringify(data);
    return api.post<ITokenResponse>(
        `${serviceUrl}/connect/token`,
        query,
        { 'Content-Type': 'application/x-www-form-urlencoded' },
        getMiddlewareOptions()
    );
};

const getMiddlewareOptions = (): IMiddlewareOptions => {
    const options: IMiddlewareOptions = {
        errorCodeOptions: {
            errorCodeComponentContext: null,
            errorCodesHandled: [400],
        },
        options: { skipTokenValidator: true },
    };

    return options;
};

export function getAccessToken(accessCode: string) {
    const { portal } = store.getState();
    const getAccessTokenRequestData: IAccessTokenRequest = {
        client_id: hybridClientIds[portal.portalType],
        code: accessCode,
        grant_type: 'authorization_code',
        redirect_uri: staticConfig.appBaseUrl,
    };
    return authenticationRequest(getAccessTokenRequestData);
}

export function refreshToken(): Promise<ITokenResponse> {
    return new Promise((r, e) => {
        if (!sessionStorage.getItem('refresh_token')) {
            clearAuthStorage();
            loginRedirect();
            return;
        }
        const { portal } = store.getState();
        const refreshTokenRequestData: IRefreshTokenRequest = {
            client_id: hybridClientIds[portal.portalType],
            grant_type: 'refresh_token',
            scope: staticConfig.auth.scopes.join(' '),
            refresh_token: sessionStorage.getItem('refresh_token'),
        };
        authenticationRequest(refreshTokenRequestData)
            .then((token) => {
                setTokenExpiration(token.expires_in);
                sessionStorage.setItem('token', token.access_token);
                sessionStorage.setItem('id_token', token.id_token);
                sessionStorage.setItem('refresh_token', token.refresh_token);
                r(token);
            })
            .catch((e) => {
                if (e.status === 400) {
                    store.dispatch(logoutUser());
                }
            });
    });
}

export function getUserId() {
    return api.get<ITokenUserInfo>(`${serviceUrl}/connect/userinfo`);
}
