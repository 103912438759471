/* eslint-disable max-len */
/* eslint-disable no-control-regex */
const emailRegexValue = new RegExp(
    /((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))/
);

export const emailRegex = new RegExp(`^${emailRegexValue.source}$`, 'i'); // regex from API (FluentValidation)
export const multipleEmailRegex = new RegExp(`^${emailRegexValue.source}+([;]\\s*(${emailRegexValue.source}+))*$`, 'g');
export const mobileNumberRegex = /^(\d+)$/;
export const mobileVerificationCodeRegex = /^(\d{4})$/;
export const digitsOrEmptyRegex = /^(|\d)+$/;
export const ipAddressRegex =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;
export const removeProtocol = /^\/\/|^.*?:\/\//;
export const illegalFileNameCharactersRegex = /[\\*/:<>?|"\x00-\x1F]/gi;
export const passwordRegex =
    /(?=^.{12,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!""#$%&'()*+,./:;<=>?@^_`{|}~[\\\]-]*$/;
export const zipPasswordRegex = /^[a-zA-Z0-9!""#$%&' ()*+,./:;<=>?@^_`{|}~[\\\]-]{1,64}$/;
export const letterOnlyRegex = /^[a-z]*$/i;
export const internalReferenceNumberRegex = /^[a-zA-Z0-9]{10}$/;
