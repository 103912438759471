import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISetUserInfo } from '../../interfaces/user.interfaces';
import { ILanguage } from '../../interfaces/globalization.interfaces';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAutorized: !!sessionStorage.getItem('token'),
        languageId: undefined,
        currentLanguage: undefined,
        acceptedTermsId: undefined,
        name: undefined,
        countryId: undefined,
        id: undefined,
        acceptedTermsDate: undefined,
        organizationId: undefined,
        userType: undefined,
        internalReferenceNumber: undefined,
    },
    reducers: {
        loginUser: (draft, action: PayloadAction<number>) => {
            const { payload } = action;

            sessionStorage.setItem('userId', payload.toString());
            sessionStorage.setItem('selectedFolderId', undefined);

            draft.id = payload;
            draft.isAutorized = true;
        },
        setUserInfo: (draft, action: PayloadAction<ISetUserInfo>) => {
            const { payload } = action;
            Object.assign(draft, payload);
        },
        setCurrentLanguage: (draft, action: PayloadAction<ILanguage>) => {
            const { payload } = action;
            draft.currentLanguage = payload;
        },
    },
});

export const { reducer: userReducer } = userSlice;
export const { loginUser, setCurrentLanguage, setUserInfo } = userSlice.actions;

export type IUserState = ReturnType<typeof userReducer>;
