import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleControl: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const bg = 'linear(to-r, red.600, pink.700)';

    return {
        w: '100%',
        transitionProperty: 'common',
        borderRadius: 'md',

        _checked: {
            bg: mode(`${c}.500`, `${c}.200`)(props),
            bgGradient: bg,
            borderColor: bg,
            color: mode('white', 'gray.900')(props),

            _hover: {
                bg: bg,
                bgGradient: 'linear(to-r, red.700, pink.800)',
                borderColor: bg,
            },
        },
    };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    control: baseStyleControl(props),
});

const sizes = {
    sm: {
        control: { h: 3, w: 3 },
        label: { fontSize: 'sm' },
        icon: { fontSize: '0.45rem' },
    },
    md: {
        control: { w: 4, h: 4 },
        label: { fontSize: 'md' },
        icon: { fontSize: '0.625rem' },
    },
    lg: {
        control: { w: 5, h: 5 },
        label: { fontSize: 'lg' },
        icon: { fontSize: '0.625rem' },
    },
    xl: {
        control: { w: 6, h: 6 },
        label: { fontSize: 'xl' },
        icon: { fontSize: '0.85rem' },
    },
};

const defaultProps = {
    size: 'xl',
};

export const Checkbox = {
    parts: parts.keys,
    baseStyle,
    sizes,
    defaultProps,
};
