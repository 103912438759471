import { MessageDescriptor } from 'react-intl';
import { IGraphQLM2M, IGraphQLEmployee } from '../interfaces/organization.interfaces';
import { ILanguage, IFilterableLanguage } from '../interfaces/globalization.interfaces';
import { IMessage, ISelectedMessage } from '../interfaces/message.interfaces';
import { IAttachmentsContainerScrollProperties, IAttachment } from '../interfaces/general.interfaces';
import { errorCodes } from '../components/form/FilePicker';
import { userType } from '../enums/adminPortal';
import { staticConfig } from '../config/static';

export const formatBytes = (bytes: number): any => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getExtensionFromString = (fileName: string): string => {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
};

export class CheckExp {
    maxAttempts = 0;
    count = 0;

    constructor(maxAttempts = 5) {
        this.maxAttempts = maxAttempts;
    }

    check = () => {
        ++this.count;
        return new Promise<void>((r, e) => {
            const delay = 1000 * (Math.exp(this.count / 2) - 1);
            if (this.count <= this.maxAttempts) {
                setTimeout(() => {
                    r();
                }, delay);
            } else {
                e();
            }
        });
    };
}

/**
 * get a URI for a static image based on image id
 * @param imageId
 * @returns
 */
export const getAssetSrc = (imageId: number) => {
    return `${staticConfig.apiBaseUrl}/staticresourceservice/v1/images/${imageId}`;
};

export function clearWhitespaces(inputString: string): string {
    return inputString.replace(/\s/g, '');
}

export function getObjDeepCopy(obj: object) {
    return JSON.parse(JSON.stringify(obj));
}

// Returns TRUE if the object is NULL or has no enumarable properties
export function isObjNullOrEmpty(obj: object) {
    return obj === null || (obj && !Object.keys(obj).length);
}

// Converts object's empty strings properties to null
export function convertObjEmptyStringsToNull(obj: any) {
    const newObject = {};
    Object.keys(obj).forEach((key: string | number) => {
        newObject[key] = obj[key] === '' ? null : obj[key];
    });
    return newObject;
}

export function buildQueryParamsStringFromType<T>(type: T): string {
    return Object.keys(type)
        .map((key) => key && `${key}=${type[key]}`)
        .join('&');
}

// Build the query string of single or comma-separated numbers depending on input type.
export function buildIdsQueryParamString(input: number | Array<number>): string {
    if (Array.isArray(input)) {
        return input.join(',');
    } else {
        return isNaN(input) ? '' : String(input);
    }
}

export const sortNumbers = (numArray: number[]) => {
    return numArray.sort((a, b) => a - b);
};

export const numArraysEquals = (numArray: number[], numArray2: number[]) => {
    const numArraySorted = sortNumbers(numArray),
        numArray2Sorted = sortNumbers(numArray2);

    return (
        numArray.length === numArray2.length && numArraySorted.every((value, index) => value === numArray2Sorted[index])
    );
};

export function htmlToPlainText(html: string) {
    const divElement = document.createElement('div');
    divElement.innerHTML = html.replace(/<(br)[^>]*>/g, '\r\n');
    return divElement.textContent || divElement.innerText || '';
}

export function plainTextToHtml(text: string) {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}

export function toUpperCaseWithUnderscores(text: string) {
    return text.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toUpperCase();
}

// Convert file to base64 string
export const fileToBase64 = (file: File) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        // Read file content on file loaded event
        reader.onloadend = function (event) {
            resolve(reader.result);
        };

        // Convert data to base64
        reader.readAsDataURL(file);
    });
};

export function sortGraphQLMemberList(
    currentLanguage: ILanguage,
    memberList: (IGraphQLM2M | IGraphQLEmployee)[],
    includeTypes: userType[]
): (IGraphQLM2M | IGraphQLEmployee)[] {
    return memberList
        .filter((member) => {
            return member.user && member.user.userType && includeTypes.includes(member.user.userType as userType);
        })
        .sort((member, member2) =>
            member.user.name.localeCompare(
                member2.user.name.toLowerCase(),
                currentLanguage ? currentLanguage.isoCode : 'en'
            )
        );
}

export function sortListByCurrentLanguage<T>(
    list: Array<T>,
    keySelector: (item: T) => string,
    currentLanguage: ILanguage
): Array<T> {
    if (!Array.isArray(list) || !list.length) return list;

    return list.sort((member1, member2) =>
        keySelector(member1).localeCompare(
            keySelector(member2).toLowerCase(),
            currentLanguage ? currentLanguage.isoCode : 'en'
        )
    );
}

export function flattenObject(obj: any) {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
}

export function checkFormErrors(inputErrors: object): boolean {
    return !Object.values(inputErrors).some((value) => value);
}

export function getGlobalCssVariables(names: string[]): string[] {
    const documentStyles = getComputedStyle(document.documentElement);
    return names.map((name) => documentStyles.getPropertyValue(name));
}

export function stringToHslColor(str: string) {
    let hash = 0;
    const saturation = 50;
    const lightness = 50;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = hash % 360;
    return `hsl(${hue},${saturation}%,${lightness}%)`;
}

export function hexToRgb(hex: string) {
    if (!hex) return '';
    const rgbHex = hex
        .trim()
        .replace('#', '')
        .match(/.{1,2}/g);
    const rgb = {
        r: parseInt(rgbHex[0], 16),
        g: parseInt(rgbHex[1], 16),
        b: parseInt(rgbHex[2], 16),
    };
    return Object.values(rgb).join(',');
}

export function getSelectedDocuments(documentList: IMessage[], selectedMessages: ISelectedMessage[]): IMessage[] {
    return documentList.filter((document) => {
        return selectedMessages.findIndex((selected) => selected.id === document.id) > -1;
    });
}

export function capitalizeString(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCrossBrowserPrefixedProp(propName: string, target: object) {
    if (typeof target[propName] !== 'undefined') {
        return propName;
    } else if (typeof target[`moz${capitalizeString(propName)}`] !== 'undefined') {
        return `moz${capitalizeString(propName)}`;
    } else if (typeof target[`ms${capitalizeString(propName)}`] !== 'undefined') {
        return `ms${capitalizeString(propName)}`;
    } else if (typeof target[`webkit${capitalizeString(propName)}`] !== 'undefined') {
        return `webkit${capitalizeString(propName)}`;
    } else {
        return undefined;
    }
}

export function trimTrailingChars(stringToTrim: string, charToTrim: string) {
    const regExp = new RegExp(`${charToTrim}+$`);
    return stringToTrim.replace(regExp, '');
}

export function getAttachmentsContainerHeight(amount: number): IAttachmentsContainerScrollProperties {
    const attachmentHeight = 48;
    const indent = 98;
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const allAttachmentsHeight = amount * attachmentHeight;

    if (viewportHeight < allAttachmentsHeight + indent) {
        return {
            containerHeight: viewportHeight - indent,
            hasScroll: true,
        };
    } else {
        return {
            containerHeight: allAttachmentsHeight - 8,
            hasScroll: false,
        };
    }
}

export function useContrastColorWhite(hexColor: string) {
    hexColor = hexColor.replace('#', '');

    if (hexColor.length === 3) {
        hexColor = hexColor
            .split('')
            .map(function (hex) {
                return hex + hex;
            })
            .join('');
    }

    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate the perceptive luminance (aka luma) - human eye favors green color...
    const luma = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
    return luma <= 0.5;
}

export function enumToObjectArray(enumObject: Record<string, any>) {
    const StringIsNumber = (value: string) => isNaN(Number(value)) === false;
    return Object.keys(enumObject)
        .filter(StringIsNumber)
        .map((key) => ({ key: enumObject[key], value: key }));
}

export function wrapContentWithDirection(content: string, isRtl: boolean) {
    return `<div dir="${isRtl ? 'rtl' : 'ltr'}">${content}</div>`;
}

export function isTranslatableValue(
    value: string,
    formatMessage: (messageDescriptor: MessageDescriptor, values?: { [key: string]: string }) => string
) {
    return value && value !== formatMessage({ id: value });
}

export const isAttachmentCountExceeded = (attachments: Array<IAttachment>) => {
    return attachments && attachments.length > 100;
};

export const isAttachmentSizeExceeded = (attachments: Array<IAttachment>, maxSize = 100000) => {
    let totalSize = 0;
    attachments &&
        attachments.map((attachment) => {
            totalSize += attachment.file.size;
        });
    return totalSize > maxSize * 1024;
};

export const generateFileErrorsList = (errors: Array<errorCodes>, attachments?: Array<IAttachment>) => {
    const errorsList = [];
    errors.includes(errorCodes.FILE_TYPES_INVALID) && errorsList.push('FILE_EXT_NOT_ALLOWED');
    (errors.includes(errorCodes.FILE_SIZE_INVALID) || isAttachmentSizeExceeded(attachments)) &&
        errorsList.push('FORM_ATTACHMENT_TOO_LARGE');
    isAttachmentCountExceeded(attachments) && errorsList.push('FILE_COUNT_NOT_VALID');
    return errorsList.map((errText) => ({ id: errText }));
};

export const concatAttachmentsErrors = (attachments: Array<IAttachment>) => {
    const concatedErrors = attachments
        .map((attachment) => attachment.errors)
        .reduce((concatedErrors: Array<errorCodes>, errors) => [...concatedErrors, ...errors], []);
    return Array.from(new Set(concatedErrors));
};

export const getAttachmentsErrorList = (attachments: Array<IAttachment>) => {
    return generateFileErrorsList(concatAttachmentsErrors(attachments), attachments);
};

export const filterSupportedLanguages = (
    languagesToFilter: IFilterableLanguage[],
    globalAvailableLanguages: ILanguage[]
) => {
    return globalAvailableLanguages.filter((lang) =>
        languagesToFilter.some((supLang) => lang.id === supLang.languageId)
    );
};
