export enum pageCategories {
    HOME = 'ADMIN_SIDEBAR_HOME',
    ADMINISTRATION = 'ADMIN_SIDEBAR_ADMINISTRATION',
    DISTRIBUTION = 'ADMIN_SIDEBAR_DISTRIBUTION',
    INSIGHTS = 'ADMIN_SIDEBAR_INSIGHTS',
    GENERAL = 'ADMIN_SIDEBAR_GENERAL',
}

export enum pages {
    MANAGE_ORGANIZATION = 'MANAGE_ORGANIZATION',
    MANAGE_SENDERS = 'MANAGE_SENDERS',
    MANAGE_EMPLOYEE = 'MANAGE_EMPLOYEE',
    SEND_MESSAGE = 'SEND_MESSAGE',
    SEND_MESSAGE_HISTORY = 'SEND_MESSAGE_HISTORY',
    SETUP_MACHINE_INTEGRATION = 'SETUP_MACHINE_INTEGRATION',
    ACTIVITY_LOG = 'ACTIVITY_LOG',
    RECIPIENT_SUPPORT_MODULE = 'RECIPIENT_SUPPORT_MODULE',
    STATISTICS_AND_ANALYSIS = 'STATISTICS_AND_ANALYSIS',
    INVITE_NEW_ORGANIZATIONS = 'INVITE_NEW_ORGANIZATIONS',
    MANAGE_LEGAL_DOCUMENTS = 'MANAGE_LEGAL_DOCUMENTS',
    UPLOAD_WELCOME_LETTER = 'UPLOAD_WELCOME_LETTER',
    ROLES_AND_PERMISSIONS = 'ROLES_AND_PERMISSIONS',
    MANAGE_NOTIFICATION_TEMPLATES = 'MANAGE_NOTIFICATION_TEMPLATES',
    MANAGE_LINKS = 'MANAGE_LINKS',
}

export enum permissions {
    MANAGE_AUTHORITY = 'ManageAuthority',
    MANAGE_ORGANIZATION_MEMBER_PERMISSIONS = 'ManageOrganizationMemberPermissions',
    MANAGE_SENDER = 'ManageSender',
    SEND_MESSAGE = 'SendMessage',
    CONFIGURE_QUOTAS = 'ConfigureQuotas',
    MANAGE_USERS = 'ManageUsers',
    ACCESS_AUDIT_LOG = 'AccessAuditLog',
    VIEW_STATISTICS_AND_ANALYSIS = 'ViewStatisticsAndAnalysis',
    SETUP_MACHINE_TO_MACHINE_INTEGRATION = 'SetupMachineToMachineIntegration',
    VIEW_SEND_MESSAGE_HISTORY = 'ViewSendMessageHistory',
    INVITE_NEW_ORGANIZATIONS = 'InviteNewOrganizations',
    MANAGE_ORGANIZATION_LEGAL_DOCUMENT = 'ManageOrganizationLegalDocument',
    MANAGE_WELCOME_LETTER = 'ManageWelcomeLetter',
    READ_ORGANIZATION_LEGAL_DOCUMENT = 'ReadOrganizationLegalDocument',
    MANAGE_SIGNING_REQUESTS = 'ManageSigningRequests',
    MANAGE_ROLES_AND_PERMISSIONS = 'ManageRolesAndPermissions',
    READ_BILLING_REPORT = 'ReadBillingReport',
    MANAGE_NOTIFICATION_TEMPLATES = 'ManageNotificationTemplates',
    MANAGE_LINKS = 'ManageLinks',
    LOOKUP_OPENING_RECEIPT = 'LookupOpeningReceipt',
}

export enum identityTypes {
    USERNAME = 1,
    MY_GOV_ID_PSN,
    MY_GOV_ID_EMAIL,
}

export enum deactivationReasonEnum {
    NONE = 'None',
    DECEASED = 'Deceased',
    EMPLOYMENT_TERMINATED = 'EmploymentTerminated',
    LOCKED = 'Locked',
    REQUESTED_BY_CITIZEN = 'RequestedByCitizen',
    DELETION_REQUESTED_BY_CITIZEN = 'DeletionRequestedByCitizen',
}

export enum organizationMemberDeactivationReason {
    EMPLOYMENT_TERMINATED = 'EmploymentTerminated',
    LOCKED = 'Locked',
}

export enum quotaPeriodEnum {
    NO_QUOTA = 'NoQuota',
    YEARLY = 'Yearly',
    MONTHLY = 'Monthly',
    DAILY = 'Daily',
}

export enum userType {
    CITIZEN = 'Citizen',
    ORGANIZATION_MEMBER = 'OrganizationMember',
    SOLUTION_ADMIN = 'SolutionAdmin',
    M2M = 'M2M',
}

export enum organizationMemberType {
    EMPLOYEE = 'Employee',
    SYSTEM = 'System',
}
