import React, { useState } from 'react';
import { forwardRef, useStyles, chakra, As, HTMLChakraProps, CSSObject, Icon, Tooltip } from '@chakra-ui/react';
import { useNavigationContext } from './Navigation';

interface NavigationItemProps extends HTMLChakraProps<'button'> {
    title?: string;
    isExpanded?: boolean;
    icon?: As<any>;
}
/**
 * Navigation section seperates the different navigation groups
 */
export const NavigationSection = forwardRef<NavigationItemProps, 'button'>(
    ({ children, title, icon, ...props }, ref) => {
        const { isCollapsed } = useNavigationContext();
        const [isExpanded, setIsExpanded] = useState(false);
        const styles = useStyles();

        const sectionListStyles: CSSObject = {
            display: 'flex',
            flexDirection: 'column',
            width: 'full',
        };

        const sectionStyles: CSSObject = {
            ...styles.section,
        };

        const buttonStyles = isCollapsed ? { justifyContent: 'center' } : {};

        const handleExpandClick = () => setIsExpanded((prev) => !prev);

        return (
            <chakra.div __css={sectionStyles} data-state={isExpanded ? 'expanded' : ''}>
                <Tooltip openDelay={500} label={title} placement="right">
                    <chakra.button
                        ref={ref}
                        __css={buttonStyles}
                        className="navigation-section-button"
                        onClick={handleExpandClick}
                        {...props}
                    >
                        <Icon as={icon} fontSize="xl" />
                        {!isCollapsed && <chakra.span ms="3">{title}</chakra.span>}
                    </chakra.button>
                </Tooltip>
                {isExpanded && <chakra.div __css={sectionListStyles}>{children}</chakra.div>}
            </chakra.div>
        );
    }
);
