export enum FilterGroups {
    SEARCH_DEACTIVATE = 'FindOnDeactivate',
    SEND_MESSAGE = 'FindOnSend',
    ACTIVITY_LOG = 'FindOnActivityLog',
}

export enum IdentityTypes {
    Username = 1,
    MyGovIdPSN = 2,
    OneIdPassport = 6,
    BahrainId = 7,
}

export enum IdentityAccessLevels {
    CITIZEN = 1,
    ADMIN = 2,
}
