import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMenubarFolderItemAction, IMenubarButtonAction } from '../../interfaces/menubar.interfaces';
import { selectAllItems, menuBarViewType } from '../../enums/menubar';

export const menuBarSlice = createSlice({
    name: 'menuBar',
    initialState: {
        selectMailItemsAction: null,
        selectedFolderMenubarItemAction: null,
        selectedMenuButtonAction: null,
        touchSelectActive: false,
        activeViewType: menuBarViewType.list,
        disableReadUnreadButton: false,
        disableMoveToFolderMenuItem: false,
        isRepliable: true,
    },
    reducers: {
        selectMailsAction: (draft, action: PayloadAction<selectAllItems>) => {
            const { payload } = action;
            draft.selectMailItemsAction = payload;
        },
        setSelectedFolderMenuBarItemAction: (draft, action: PayloadAction<IMenubarFolderItemAction>) => {
            const { payload } = action;
            draft.selectedFolderMenubarItemAction = payload;
        },
        setSelectedMenuBarButtonAction: (draft, action: PayloadAction<IMenubarButtonAction>) => {
            const { payload } = action;
            draft.selectedMenuButtonAction = payload;
        },
        setTouchSelectActive: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.touchSelectActive = payload;
        },
        setActiveViewType: (draft, action: PayloadAction<menuBarViewType>) => {
            const { payload } = action;
            draft.activeViewType = payload;
        },
        setEnableDisableReadUnreadButton: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.disableReadUnreadButton = payload;
        },
        setEnableDisableMoveToFolderMenuItem: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.disableMoveToFolderMenuItem = payload;
        },
        setReplyAvailable: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.isRepliable = payload;
        },
    },
});

export const { reducer: menuBarReducer } = menuBarSlice;
export const {
    selectMailsAction,
    setActiveViewType,
    setEnableDisableMoveToFolderMenuItem,
    setEnableDisableReadUnreadButton,
    setSelectedFolderMenuBarItemAction,
    setSelectedMenuBarButtonAction,
    setTouchSelectActive,
    setReplyAvailable,
} = menuBarSlice.actions;
export type IMenuBarState = ReturnType<typeof menuBarReducer>;
