import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IDelegatedAccess } from 'src/interfaces';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';

const baseUrl = staticConfig.apiBaseUrl;
const tagTypes = ['DelegatedAccess'];
const defaultMailboxId = +localStorage.getItem('mailboxId');

interface IDelegatedAccessRequest {
    mailboxId: string;
    userReference: string;
    data: {
        role: number;
        endDate: string;
    };
}

interface IUserReferenceResponse {
    userReference: string;
}

export const delegatedAccessApi = createApi({
    reducerPath: 'delegatedAccessApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getDelegatedAccess: builder.query<IDelegatedAccess[], void>({
            query: () => {
                return `mailbox/access`;
            },
            providesTags: tagTypes,
        }),
        getDelegatedAccessor: builder.query<IDelegatedAccess[], number>({
            query: (mailboxId = defaultMailboxId) => {
                return `mailbox/${mailboxId}/access`;
            },
            providesTags: tagTypes,
        }),
        createDelegatedAccess: builder.mutation({
            query: (request: IDelegatedAccessRequest) => ({
                url: `mailbox/${request.mailboxId}/access/${request.userReference}`,
                method: 'POST',
                body: request.data,
            }),
            invalidatesTags: tagTypes,
        }),
        getUserReference: builder.query<IUserReferenceResponse, void>({
            query: () => {
                return `mailbox/access/userReference`;
            },
            providesTags: tagTypes,
        }),
    }),
});

export const {
    useCreateDelegatedAccessMutation,
    useGetUserReferenceQuery,
    useGetDelegatedAccessQuery,
    useGetDelegatedAccessorQuery,
} = delegatedAccessApi;
