import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';
import { INotificationSettings, INotificationSettingsEntity, IResponseCollection } from '../../../../interfaces';

const baseUrl = staticConfig.apiBaseUrl + '/notificationservice/v2';

const tagTypes = ['Notification'];

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getNotificationSettings: builder.query<IResponseCollection<INotificationSettingsEntity>, void>({
            query: () => '/user-notification-settings',
            providesTags: tagTypes,
        }),
        getmailSettings: builder.query<IResponseCollection<INotificationSettingsEntity>, void>({
            query: () => '/user-notification-settings',
            providesTags: tagTypes,
        }),
        updateNotificationSettings: builder.mutation({
            query: ({ isEmailNotificationEnabled, isSmsNotificationEnabled }: INotificationSettings) => ({
                url: '/user-notification-settings',
                body: {
                    userId: +sessionStorage.getItem('userId'),
                    isEmailNotificationEnabled,
                    isSmsNotificationEnabled,
                },
                method: 'PUT',
            }),
            invalidatesTags: tagTypes,
        }),
    }),
});

export const { useGetNotificationSettingsQuery, useUpdateNotificationSettingsMutation, useGetmailSettingsQuery } =
    notificationApi;
