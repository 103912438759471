import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
    transitionDuration: 'normal',
    transitionProperty: 'all',
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    bg: 'blackAlpha.300',
    zIndex: 'overlay',
};

export const Overlay = {
    baseStyle,
};
