import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';
import type { IOrganization } from '../../../../interfaces';

const baseUrl = staticConfig.apiBaseUrl + '/organizationservice/v2';

const tagTypes = ['Organization'];

export const organizationApi = createApi({
    reducerPath: 'organizationApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getOrganization: builder.query<IOrganization, number>({
            query: (organizationId) => `/organizations/${organizationId}`,
            providesTags: tagTypes,
        }),
    }),
});

export const { useGetOrganizationQuery } = organizationApi;
