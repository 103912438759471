import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getColor, mode } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
    field: {
        width: '100%',
        minWidth: 0,
        outline: 0,
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'common',
        transitionDuration: 'normal',
    },
};

const size: Record<string, SystemStyleObject> = {
    lg: {
        fontSize: 'md',
        px: 4,
        h: 12,
        borderRadius: 'lg',
    },
    md: {
        fontSize: 'md',
        px: 4,
        h: 10,
        borderRadius: 'lg',
    },
    sm: {
        fontSize: 'sm',
        px: 3,
        h: 8,
        borderRadius: 'sm',
    },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    lg: {
        field: size.lg,
        addon: size.lg,
    },
    md: {
        field: size.md,
        addon: size.md,
    },
    sm: {
        field: size.sm,
        addon: size.sm,
    },
};

const getDefaults = (props: Record<string, any>) => {
    const { focusBorderColor: fc, errorBorderColor: ec } = props;
    return {
        focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
        errorBorderColor: ec || mode('red.500', 'red.300')(props),
    };
};

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
    const { theme } = props;
    const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

    return {
        field: {
            border: '1px solid',
            borderColor: 'inherit',
            bg: 'white',
            _hover: {
                borderColor: mode('gray.700', 'whiteAlpha.200')(props),
            },
            _readOnly: {
                boxShadow: 'none !important',
                userSelect: 'all',
            },
            _disabled: {
                opacity: 0.4,
                cursor: 'not-allowed',
            },
            _invalid: {
                borderColor: getColor(theme, ec),
                boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
            },
            _focus: {
                zIndex: 1,
                borderColor: getColor(theme, fc),
                boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
            },
        },
        addon: {
            border: '1px solid',
            borderColor: mode('inherit', 'whiteAlpha.50')(props),
            bg: mode('gray.100', 'whiteAlpha.300')(props),
        },
    };
};

const variantUnstyled: PartsStyleObject<typeof parts> = {
    field: {
        bg: 'transparent',
        px: 0,
        height: 'auto',
    },
    addon: {
        bg: 'transparent',
        px: 0,
        height: 'auto',
    },
};

const variants = {
    outline: variantOutline,
    unstyled: variantUnstyled,
};

const defaultProps = {
    size: 'md',
    variant: 'outline',
};

export const Input = {
    parts: parts.keys,
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
