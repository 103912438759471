import React from 'react';
import {
    forwardRef,
    chakra,
    HTMLChakraProps,
    useStyleConfig,
    SystemStyleObject,
    useConst,
    omitThemingProps,
    ThemingProps,
} from '@chakra-ui/react';

interface FilePickerProps extends Omit<HTMLChakraProps<'input'>, 'size'>, ThemingProps<'FilePicker'> {}

export const FilePicker = forwardRef<FilePickerProps, 'input'>(
    ({ children, variant, colorScheme, size, sx, id, ...props }, ref) => {
        const { ...component } = omitThemingProps(props);
        const styles = useStyleConfig('FilePicker', { variant, colorScheme, size });
        const pseudoRandom = useConst(() => Math.random());
        const idFinal = id ? id : `filepicker-${pseudoRandom}`;

        const inputStyles: SystemStyleObject = {
            display: 'none',
        };

        return (
            <chakra.label htmlFor={idFinal} __css={styles} sx={sx}>
                {children}
                <chakra.input type="file" id={idFinal} ref={ref} __css={inputStyles} {...component} />
            </chakra.label>
        );
    }
);

FilePicker.displayName = 'FileUploader';
