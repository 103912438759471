export enum SupportedMimeType {
    PDF = 'application/pdf',
    TXT = 'text/plain',
    HTML = 'text/html',
}

export enum SupportedFileType {
    'application/pdf' = 'pdf',
    'application/msword' = 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
    'application/vnd.ms-excel' = 'xls',
    'application/vnd.ms-powerpoint' = 'ppt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'pptx',
    'application/vnd.oasis.opendocument.presentation' = 'odp',
    'application/vnd.oasis.opendocument.spreadsheet' = 'ods',
    'application/vnd.oasis.opendocument.text' = 'odt',
    'application/rtf' = 'rtf',
    'text/html' = 'html',
    'text/htm' = 'htm',
    'text/plain' = 'txt',
    'image/png' = 'png',
    'image/jpeg' = 'jpeg',
    'image/jpg' = 'jpg',
    'image/bmp' = 'bmp',
    'image/gif' = 'gif',
    'image/tiff' = 'tiff',
    'image/tif' = 'tif',
    'application/vnd+e-boks.emf+xml' = 'xml',
    'application/vnd+e-boks.emf+json' = 'json',
    'application/vnd+e-boks.signingrequest+json' = 'json',
    'text/csv' = 'csv',
}
