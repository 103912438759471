export function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}

export function isFirefox() {
    return navigator.userAgent.indexOf('Firefox') > 0;
}

export const isTouchScreen = () => {
    return 'ontouchstart' in document.documentElement;
};

export const getHostname = (): string => globalThis?.location?.hostname;
