export enum ErrorContext {
    CREATE_FOLDER = 'CreateFolder',
    ORGANIZATION_FORM = 'OrganizationForm',
    SENDER_FORM = 'SenderForm',
    MOVE_MESSAGES = 'MoveMessages',
    TRASH_MESSAGES = 'TrashMessages',
    USERS_SCREEN = 'UsersScreen',
    UPDATE_FOLDER = 'UpdateFolder',
    DELETE_FOLDER = 'DeleteFolder',
    UNVERIFY_EMAIL = 'UnverifyEmail',
    VERIFY_MOBILE = 'VerifyMobile',
    PROFILE_FORM = 'ProfileForm',
    GRANT_ACCESS = 'GrantAccess',
    USER_TERMS = 'UserTerms',
}
