import { ILanguage, ICountry } from '../interfaces/globalization.interfaces';
import * as api from '../middleware/api.middleware';
import { IResponseCollection } from '../interfaces/general.interfaces';

const serviceUrl = '/globalizationservice/v2';

export function getGlobalAvailableLanguages() {
    return api.get<IResponseCollection<ILanguage>>(`${serviceUrl}/languages`);
}

export function getCountries() {
    return api.get<IResponseCollection<ICountry>>(`${serviceUrl}/countries`);
}
