export const semanticTokens = {
    colors: {
        error: 'red.500',
        success: 'green.500',
        warning: 'yellow.500',
        info: 'blue.500',
        primary: {
            default: 'red.600',
        },
        secondary: {
            default: 'pink.700',
        },
        primaryGradient: {
            default: 'red.600',
        },
        secondaryGradient: {
            default: 'pink.700',
        },
    },
};
