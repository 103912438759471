import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getObjDeepCopy } from '../../helpers/general';
import { IReply } from '../../interfaces/mailbox.interfaces';
import {
    IMessage,
    ISelectedMessage,
    IUnreadCount,
    ITrashedUnreadCount,
    IMailitemAction,
    ISelectedFolder,
    IFolder,
    IUpdatedFoldersAction,
    folderActions,
    ICountItem,
} from '../../interfaces';
import { filterAction, sortAction } from '../../enums';

const mailboxSlice = createSlice({
    name: 'mailbox',
    initialState: {
        selectedFolder: {
            id: undefined,
            name: undefined,
            parentId: undefined,
        },
        mailboxId: undefined,
        folderList: [],
        documentList: [],
        selectedDocuments: [],
        foldersUpdateAction: null,
        unreadCounter: null,
        trashedUnreadCounter: null,
        searchValue: '',
        searchInputText: '',
        sortOrder: sortAction.SORT_BY_DATE_NEWEST_TOP,
        filterBy: null,
        searchEnabledOutsideMailbox: false,
        editNoteDocument: undefined,
        selectedFolderAction: null,
        isMailboxMounted: false,
        mailItemAction: null,
        currentPage: 0,
        mailItemUpdated: false,
        reply: {
            threadReply: undefined,
            available: true,
            senderId: undefined,
            userId: undefined,
        },
        sendOpenReceipt: false,
    },
    reducers: {
        selectMailbox: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            draft.mailboxId = payload;

            // Sideeffect fix for issues loading the sidebar and mailbox
            // See (src\app\user\layouts\main.tsx) for more info.
            localStorage.setItem('mailboxId', `${payload}`);
        },
        selectFolder: (draft, action: PayloadAction<ISelectedFolder>) => {
            const { payload } = action;
            sessionStorage.setItem('selectedFolderId', `${payload ? payload.id : null}`);

            draft.selectedFolder = payload;
            draft.selectedDocuments = [];
            draft.documentList = [];
            draft.searchValue = draft.searchEnabledOutsideMailbox ? draft.searchValue : '';
            draft.searchEnabledOutsideMailbox = false;
        },
        setFolderList: (draft, action: PayloadAction<IFolder[]>) => {
            const { payload } = action;
            draft.folderList = payload;
        },
        setDocumentList: (draft, action: PayloadAction<IMessage[]>) => {
            const { payload } = action;
            draft.documentList = draft.documentList.concat(payload);
        },
        replaceDocument: (draft, action: PayloadAction<IMessage>) => {
            const { payload } = action;

            const indexToReplace = draft.documentList.findIndex((doc) => doc.id === payload.id);
            if (indexToReplace > -1) {
                const documentList = getObjDeepCopy(draft.documentList);
                documentList[indexToReplace] = payload;
                draft.mailItemUpdated = true;
                draft.documentList = documentList;
            }
        },
        fillDocumentList: (draft, action: PayloadAction<IMessage[]>) => {
            const { payload } = action;
            draft.documentList = payload;
        },
        cleanDocumentList: (draft) => {
            draft.documentList = [];
        },
        setSelectedDocuments: (draft, action: PayloadAction<ISelectedMessage[]>) => {
            const { payload } = action;
            draft.selectedDocuments = payload;
        },
        setFolderListUpdatedAction: (draft, action: PayloadAction<IUpdatedFoldersAction>) => {
            const { payload } = action;
            draft.foldersUpdateAction = payload;
        },
        updateMailboxDocument: (draft, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            const docIndex = draft.documentList.findIndex((doc) => doc.id === payload.id);
            const updateDocumentList = [...draft.documentList];
            updateDocumentList[docIndex] = { ...updateDocumentList[docIndex], ...payload };

            draft.documentList = updateDocumentList;
        },
        setUnreadCounter: (draft, action: PayloadAction<IUnreadCount>) => {
            const { payload } = action;
            const unreadResult = payload;
            unreadResult.unreadCount = 0;
            unreadResult.items.forEach((item: ICountItem) => {
                unreadResult.unreadCount += item._count.id;
            });
            draft.unreadCounter = unreadResult;
        },
        removeDocument: (draft, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            const newDocumentList = draft.documentList.filter((doc) => {
                return doc.id !== payload.id;
            });
            draft.selectedDocuments = [];
            draft.documentList = newDocumentList;
        },
        setTrashedUnreadCounter: (draft, action: PayloadAction<ITrashedUnreadCount>) => {
            const { payload } = action;
            const trashedUnreadCountResult = payload;
            trashedUnreadCountResult.trashedUnreadCount = 0;
            trashedUnreadCountResult.items.forEach((item: ICountItem) => {
                trashedUnreadCountResult.trashedUnreadCount += item._count.id;
            });
            draft.trashedUnreadCounter = trashedUnreadCountResult;
        },
        updateFolderName: (draft, action: PayloadAction<{ folderId: number; folderName: string }>) => {
            const { folderId, folderName } = action.payload;
            const folderIndex = draft.folderList.findIndex((folder) => folder.id === folderId);
            const updatedFolderList = [...draft.folderList];
            updatedFolderList[folderIndex] = { ...updatedFolderList[folderIndex], name: folderName };
            draft.folderList = updatedFolderList;
            draft.selectedFolder = {
                id: updatedFolderList[folderIndex].id,
                parentId: updatedFolderList[folderIndex].parentId,
                name: updatedFolderList[folderIndex].name,
            };
        },
        removeDocumentById: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            const removedDocumentList = draft.documentList.filter((doc) => {
                return doc.id !== payload;
            });
            draft.selectedDocuments = [];
            draft.documentList = removedDocumentList;
        },
        setSearchValue: (draft, action: PayloadAction<string>) => {
            const { payload } = action;
            draft.searchValue = payload;
        },
        setSortOrder: (draft, action: PayloadAction<sortAction>) => {
            const { payload } = action;
            draft.sortOrder = payload;
        },
        setFilterBy: (draft, action: PayloadAction<filterAction>) => {
            const { payload } = action;
            draft.filterBy = payload;
        },
        setSearchInputText: (draft, action: PayloadAction<string>) => {
            const { payload } = action;
            draft.searchInputText = payload;
        },
        setSearchOutsideMailbox: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.searchEnabledOutsideMailbox = payload;
        },
        unselectDocumentById: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            const newSelectedDocumentsList = draft.selectedDocuments.filter((doc) => {
                return doc.id !== payload;
            });
            draft.selectedDocuments = newSelectedDocumentsList;
        },
        selectDocumentById: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            const newSelectedDocumentsListNotIncluded = draft.selectedDocuments.filter(
                (selectedItem: ISelectedMessage) => {
                    return selectedItem.id !== payload;
                }
            );
            newSelectedDocumentsListNotIncluded.push({ id: payload } as ISelectedMessage);
            draft.selectedDocuments = newSelectedDocumentsListNotIncluded;
        },
        setEditNoteDocument: (draft, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            draft.editNoteDocument = payload;
        },
        setSelectedFolderAction: (draft, action: PayloadAction<folderActions>) => {
            const { payload } = action;
            draft.selectedFolderAction = payload;
        },
        setIsMailboxMounted: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.isMailboxMounted = payload;
        },
        setMailitemAction: (draft, action: PayloadAction<IMailitemAction>) => {
            const { payload } = action;
            draft.mailItemAction = payload;
        },
        setCurrentPage: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            draft.currentPage = payload;
        },
        clearItemUpdated: (draft) => {
            draft.mailItemUpdated = false;
        },
        setReplyInfo: (draft, action: PayloadAction<IReply>) => {
            const { payload } = action;
            draft.reply = {
                senderId: payload.senderId,
                userId: payload.userId,
                available: payload.available,
                threadReply: payload.threadReply,
            };
        },
        updateOpenReceipt: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.sendOpenReceipt = payload;
        },
        resetSendOpenReceipt: (draft, action: PayloadAction<Array<number>>) => {
            const { payload } = action;
            draft?.documentList.forEach((document) => {
                document?.artifacts.forEach((artifact) => {
                    if ((payload && payload.includes(artifact.id)) || !payload) {
                        artifact.sendOpenReceipt = false;
                    }
                });
            });
        },
    },
});

export const { reducer: mailboxReducer } = mailboxSlice;
export const {
    cleanDocumentList,
    clearItemUpdated,
    fillDocumentList,
    removeDocument,
    removeDocumentById,
    replaceDocument,
    selectDocumentById,
    selectFolder,
    selectMailbox,
    setCurrentPage,
    setDocumentList,
    setEditNoteDocument,
    setFilterBy,
    setFolderList,
    setFolderListUpdatedAction,
    setIsMailboxMounted,
    setMailitemAction,
    setSearchInputText,
    setSearchOutsideMailbox,
    setSearchValue,
    setSelectedDocuments,
    setSelectedFolderAction,
    setSortOrder,
    setTrashedUnreadCounter,
    setUnreadCounter,
    unselectDocumentById,
    updateFolderName,
    updateMailboxDocument,
    setReplyInfo,
    updateOpenReceipt,
    resetSendOpenReceipt,
} = mailboxSlice.actions;

export type IMailboxState = ReturnType<typeof mailboxReducer>;
