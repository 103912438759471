export enum sortAction {
    SORT_BY_DATE_NEWEST_BOTTOM = 1,
    SORT_BY_DATE_NEWEST_TOP = 2,
    SORT_BY_NOTES = 3,
    SORT_BY_SENDER_ATO_Z = 4,
    SORT_BY_TITLE_ATO_Z = 5,
}

export enum filterAction {
    FILTER_BY_UNREAD = 1,
}

export enum sortFilterType {
    SORT = 1,
    FILTER = 2,
}
