import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPortalId, setPortalType } from '../../redux/portal';
import Loader from '../Loader';
import { staticConfig } from '../../config/static';
import { getPortalInfoByDomain } from '../../providers/portal.provider';
import { portalType } from '../../enums/portal';
import { Center } from '../../ui-components';

/**
 *
 * @developer notes:
 * This component has not been modifed further than the replacement of chakra components.
 * The original business logic is still the same. The content of use effect should be
 * Moved to rtk or something similar.
 */
export const PortalType: FC = ({ children }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let portalType = localStorage.getItem('portalType') as portalType;
        if (portalType) {
            dispatch(setPortalType(portalType));
        }
        getPortalInfoByDomain(staticConfig.appHost).then((portalInfo) => {
            portalType = portalInfo.portalTemplateType;
            dispatch(setPortalType(portalType));
            dispatch(setPortalId(portalInfo.id));
            localStorage.setItem('portalType', portalType);
            setIsLoading(false);
        });
    }, [dispatch]);

    if (isLoading) {
        return (
            <Center w="full" h="full">
                <Loader show size="lg" />
            </Center>
        );
    }

    return <>{children}</>;
};
