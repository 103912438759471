import { space } from './space';
/**
 * Generic sizes
 * use for all CSS sizing properties e.g. width, height, padding, margin etc.
 */
const largeSizes = {
    max: 'max-content',
    min: 'min-content',
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    '8xl': '90rem',
};

/**
 * App navigation header heights
 */
const header = {
    sm: '3.5rem',
    md: '4rem',
    lg: '6rem',
};

/**
 * Generic container sizes
 */
const container = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
};

export const sizes = {
    ...space,
    ...largeSizes,
    container,
    header,
};
