import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../redux/interfaces';
// Store
import { loginUser } from '../redux/user';
import { logoutUser } from '../redux/auth';
import { getAccessToken, getUserId, refreshToken } from '../providers/login.provider';
// Helpers
import { parseQueryString } from '../helpers/queryString';
import { setTokenExpiration, loginRedirect, shouldSkipAuth } from '../helpers/auth';
// Components
import Loader from '../components/Loader';

interface IAuthState {
    loaded: boolean;
}

class Auth extends React.Component<ReduxType, IAuthState> {
    constructor(props: ReduxType) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    getUser = () => {
        return getUserId().then((data) => {
            sessionStorage.setItem('userOrganizationId', data.user_organization_id);
            this.props.loginUser(+data.sub);
        });
    };

    componentDidMount() {
        if (this.props.isAutorized || shouldSkipAuth()) {
            this.setState({ loaded: true });
        } else {
            const queryParams = parseQueryString(window.location.hash.substring(1));


            if (queryParams.code) {
                getAccessToken(queryParams.code as any)
                    .then((res) => {
                        setTokenExpiration(res.expires_in);
                        window.sessionStorage.setItem('token', res.access_token);
                        window.sessionStorage.setItem('id_token', res.id_token);
                        window.sessionStorage.setItem('refresh_token', res.refresh_token);
                        window.localStorage.removeItem('userWasCreated');
                        this.getUser().then(() => {
                            this.setState({ loaded: true });
                        });
                    })
                    .catch((err) => {
                        this.props.logoutUser();
                    });
            } else if (window.sessionStorage.getItem('refresh_token')) {
                refreshToken().then(() => {
                    this.getUser().then(() => {
                        this.setState({ loaded: true });
                    });
                });
            } else {
                loginRedirect();
            }
        }
    }

    renderLoader = () => {
        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                <Loader show size="lg" />
            </div>
        );
    };

    public render() {
        return this.state.loaded ? this.props.children : this.renderLoader();
    }
}

const mapStateToProps = ({ user }: IRootState) => {
    return {
        isAutorized: user.isAutorized,
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        loginUser: (userId: number) => dispatch(loginUser(userId)),
        logoutUser: () => dispatch(logoutUser()),
    };
};

type ReduxType = ReturnType<typeof mapDispatcherToProps> & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(Auth);
