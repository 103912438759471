import { avatarAnatomy as parts } from '@chakra-ui/anatomy';
import { isDark, mode, randomColor } from '@chakra-ui/theme-tools';
import type { PartsStyleFunction, PartsStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { sizes } from '../foundation/sizes';

const baseStyleContainer: SystemStyleFunction = (props) => {
    const { name, theme } = props;
    const bg = name ? randomColor({ string: name }) : 'white';
    const isBgDark = isDark(bg)(theme);

    let color = 'red.600';
    if (!isBgDark) color = 'red.600';

    const borderColor = mode('gray.100', 'gray.100')(props);

    return {
        bg,
        color,
        borderColor,
        borderWidth: '1px',
        verticalAlign: 'top',
    };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    container: baseStyleContainer(props),
});

function getSize(size: string): PartsStyleObject<typeof parts> {
    const themeSize = sizes[size];
    return {
        container: {
            width: size,
            height: size,
            fontSize: `calc(${themeSize ?? size} / 1.9)`,
        },
        excessLabel: {
            width: size,
            height: size,
        },
        label: {
            fontSize: `calc(${themeSize ?? size} / 1.9)`,
            lineHeight: size !== '100%' ? themeSize ?? size : undefined,
        },
    };
}

const avatarSizes = {
    '2xs': getSize('4'),
    xs: getSize('6'),
    sm: getSize('8'),
    md: getSize('12'),
    lg: getSize('16'),
    xl: getSize('24'),
    '2xl': getSize('32'),
    full: getSize('100%'),
};

const defaultProps = {
    size: 'md',
};

export const Avatar = {
    baseStyle,
    sizes: avatarSizes,
    defaultProps,
};
