import type { SystemStyleObject } from '@chakra-ui/theme-tools';
import { variantPrimary, variantGhost, variantOutline } from './button';

const baseStyle: SystemStyleObject = {
    lineHeight: '1.2',
    borderRadius: 'full',
    fontWeight: 'semibold',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    verticalAlign: 'middle',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    _focus: {
        boxShadow: 'outline',
    },
    _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none',
    },
    _hover: {
        _disabled: {
            bg: 'initial',
        },
    },
};

const sizes: Record<string, SystemStyleObject> = {
    lg: {
        h: 12,
        minW: 12,
        fontSize: 'md',
        px: 6,
    },
    md: {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
    },
};

const variants = {
    primary: variantPrimary,
    ghost: variantGhost,
    outline: variantOutline,
};

const defaultProps = {
    size: 'md',
    variant: 'primary',
    colorScheme: 'red',
};

export const FilePicker = {
    variants,
    baseStyle,
    sizes,
    defaultProps,
};
