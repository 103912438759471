import type { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = ({ colorMode }) => {
    return {
        transitionProperty: 'common',
        transitionDuration: 'fast',
        transitionTimingFunction: 'ease-out',
        cursor: 'pointer',
        textDecoration: 'underline',
        outline: 'none',
        color: `${colorMode}.600`,
        _hover: {
            textDecoration: 'underline',
        },
        _focus: {
            boxShadow: 'outline',
        },
    };
};

const sizes: Record<string, SystemStyleObject> = {
    xl: {
        fontSize: 'xl',
    },
    lg: {
        fontSize: 'lg',
    },
    md: {
        fontSize: 'md',
    },
};

const defaultProps = {
    colorMode: 'red',
    size: 'md',
};

export const Link = {
    baseStyle,
    sizes,
    defaultProps,
};
