// TokenRefresherQueueMiddleware
import { TokenRefresherQueueMiddleware } from '../helpers/tokenExpirationValidator';
// Request
import { typedRequest } from './request';
// Interfaces
import { IMiddlewareOptions } from './interfaces';
// Helpers
import { getHeaders } from './helpers';
// Create new token expiration validator instance
const tokenRefresherQueueMiddleware = new TokenRefresherQueueMiddleware();

function get<T>(
    url: string,
    options?: object,
    middlewareOptions?: IMiddlewareOptions,
    getPaginationHeaders?: boolean
): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'GET',
                        headers: getHeaders(options),
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions,
                    getPaginationHeaders
                )
            );
        });
    });
}

function post<T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'POST',
                        headers: getHeaders(options),
                        body: typeof data === 'object' ? JSON.stringify(data) : data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

function patch<T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'PATCH',
                        headers: getHeaders(options),
                        body: typeof data === 'object' ? JSON.stringify(data) : data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

function put<T>(url: string, data: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'PUT',
                        headers: getHeaders(options),
                        body: typeof data === 'object' ? JSON.stringify(data) : data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

function putFormData<T>(
    url: string,
    data: FormData,
    options?: object,
    middlewareOptions?: IMiddlewareOptions
): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'PUT',
                        headers: getHeaders(options, true),
                        body: data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

function postFormData<T>(
    url: string,
    data: FormData,
    options?: object,
    middlewareOptions?: IMiddlewareOptions
): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(middlewareOptions && middlewareOptions.options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'POST',
                        headers: getHeaders(options, true),
                        body: data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

function del<T>(url: string, data?: object, options?: object, middlewareOptions?: IMiddlewareOptions): Promise<T> {
    return new Promise<T>((resolve) => {
        tokenRefresherQueueMiddleware.check(options).finally(() => {
            resolve(
                typedRequest<T>(
                    url,
                    {
                        method: 'DELETE',
                        headers: getHeaders(options),
                        body: typeof data === 'object' ? JSON.stringify(data) : data,
                    },
                    middlewareOptions && middlewareOptions.errorCodeOptions
                )
            );
        });
    });
}

const testables = {
    tokenRefresherQueueMiddleware,
};

export { get, post, put, putFormData, postFormData, del, patch, testables };
