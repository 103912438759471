import React, { ReactNode } from 'react';
// Styles
import styles from './button.module.scss';

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
    id: string;
    children: ReactNode;
    type?: 'button' | 'reset' | 'submit';
    variant?: 'primary' | 'secondary' | 'plain' | 'disabled' | 'outline' | 'ghost';
    className?: string;
    isSmall?: boolean;
    isLink?: boolean;
}

const Button = ({
    children,
    variant = 'primary',
    type = 'button',
    className = '',
    isLink,
    isSmall,
    ...buttonProps
}: IButtonProps) => {
    return (
        <button
            {...buttonProps}
            tabIndex={isLink ? -1 : 0}
            type={type}
            className={`${styles.button} ${styles[variant]} ${className} ${isLink ? styles.link : ''} ${
                isSmall ? `${styles.small} text-small` : 'text-medium'
            } bolder`}
        >
            {children}
        </button>
    );
};

export default Button;
