import * as api from '../middleware/api.middleware';
import {
    IOrganization,
    IOrganizationLanguageItem,
    IOrganizationTermsItem,
    IOrganizationContactInfoRequest,
    IOrganizationContactInfo,
    IOrganizationCreate,
    IOrganizationContactInfoCreate,
    IOrganizationMemberItem,
    IMemberPermissionItem,
    IGraphQLM2MResponse,
    IOrganizationUpdate,
    IOrganizationContactInfoUpdate,
    IM2mOrganizationMemberCreate,
    IOrganizationMemberContactInfo,
    IOrganizationMemberCreate,
    IOrganizationMemberCreateResponse,
    IOrganizationMemberAdd,
    IOrganizationMemberAddResponse,
    IOrganizationMemberItemWithPermissions,
    IMemberRoleItem,
    ILegalDocumentsParameters,
    ILegalDocumentData,
    ILegalDocumentItem,
    IOrganizationOnboardingInvite,
    IAcceptedLegalDocumentItemExtended,
    IRolesCounts,
    IOrganizationUserTermsPayload,
} from '../interfaces/organization.interfaces';
import { IResponseCollection, IIdentifier } from '../interfaces/general.interfaces';
import { buildQueryParamsStringFromType } from '../helpers/general';
import { getMiddlewareOptionsWithContext } from '../middleware/helpers';
import { ErrorContext } from '../enums/context';
import { organizationMemberType, organizationMemberDeactivationReason } from '../enums/adminPortal';
import * as graphqlQueryBuilder from '../helpers/graphqlQueryBuilder';
import { IMiddlewareOptions } from '../middleware/interfaces';
import { defaultConfig } from '../config/default.config';
import { graphqlRequest } from './graphql.provider';

const serviceUrl = '/organizationservice/v2';
const aggregatorUrl = '/aggregatorservice/';

export function getOrganizations() {
    return api.get<IResponseCollection<IOrganization>>(`${serviceUrl}/organizations?$sort=name`);
}

export function getOrganizationById(organizationId: number) {
    return api.get<IOrganization>(`${serviceUrl}/organizations/${organizationId}`);
}

export function createOrganization(data: IOrganizationCreate) {
    return api.post<IIdentifier>(`${serviceUrl}/organizations`, data);
}

export function updateOrganization(organizationId: number, data: IOrganizationUpdate) {
    const middlewareOptions: IMiddlewareOptions = {
        errorCodeOptions: { errorCodesHandled: [403] },
    };

    return api.post<any>(`${serviceUrl}/organizations/${organizationId}/update`, data, null, middlewareOptions);
}

export function getOrganizationSettings(organizationId: number) {
    return api.get<IOrganization>(`${serviceUrl}/organizations/${organizationId}`);
}

export function getUserOrganizations(userId: number, organizationId: number) {
    return api.get<IOrganizationMemberItem[]>(
        `${serviceUrl}/organization-members/resolve-from-root-organization?userId=${userId}&organizationId=${organizationId}`
    );
}

export function getOrganizationSupportedLanguages(organizationId?: number) {
    let url = `${serviceUrl}/organization-supported-languages?`;
    if (organizationId) url = `${url}organizationId=${organizationId}`;
    return api.get<IResponseCollection<IOrganizationLanguageItem>>(url);
}

export function getUserAssociatedOrganizations(userId: number) {
    return api.get<IResponseCollection<IOrganizationMemberItem>>(`${serviceUrl}/organization-members?userId=${userId}`);
}

export function getTerms(organizationId: number) {
    return api.get<IResponseCollection<IOrganizationTermsItem>>(
        `${serviceUrl}/organization-user-terms?organizationId=${organizationId}&isActive=true`
    );
}

export function createOrganizationMember(data: IOrganizationMemberCreate) {
    return api.post<IOrganizationMemberCreateResponse>(`${serviceUrl}/organization-members/create-user`, {
        ...data,
        activationStatus: true,
    });
}

export function removeOrganizationMember(memberId: number) {
    return api.del<null>(`${serviceUrl}/organization-members/${memberId}`);
}

export function updateOrganizationMember(id: number, grantAccessToSubOrganizations: boolean) {
    return api.post<IIdentifier>(`${serviceUrl}/organization-members/${id}/update`, { grantAccessToSubOrganizations });
}

export function getMemberPermissions(memberId: number) {
    return api.get<IResponseCollection<IMemberPermissionItem>>(
        `${serviceUrl}/organization-member-permissions?organizationMemberId=${memberId}`
    );
}

export function getMemberRoles(memberId: number) {
    return api.get<IResponseCollection<IMemberRoleItem>>(
        `${serviceUrl}/organization-member-roles?organizationMemberId=${memberId}`
    );
}

export function getUserPermissions(organizationId: number) {
    const url = `${serviceUrl}/organization-members/resolve-with-inheritance?userId=${sessionStorage.getItem(
        'userId'
    )}&organizationId=${organizationId}`;
    return api.get<IOrganizationMemberItemWithPermissions>(url);
}

export function updateMemberPermissions(organizationMemberId: number, permissionIds: number[]) {
    return api.put<null>(`${serviceUrl}/organization-member-permissions/update-permissions`, {
        organizationMemberId,
        permissionIds,
    });
}

export function updateMemberRoles(organizationMemberId: number, roleIds: number[]) {
    return api.put<null>(`${serviceUrl}/organization-member-roles/update-roles`, { organizationMemberId, roleIds });
}

export function getOrganizationContactInfos(requestData?: IOrganizationContactInfoRequest) {
    let url = `${serviceUrl}/organization-contact-information?`;
    if (requestData) url = url + buildQueryParamsStringFromType<IOrganizationContactInfoRequest>(requestData);
    return api.get<IResponseCollection<IOrganizationContactInfo>>(url);
}

export function createOrganizationContactInfo(data: IOrganizationContactInfoCreate, context?: ErrorContext) {
    return api.post<IIdentifier>(
        `${serviceUrl}/organization-contact-information`,
        data,
        null,
        getMiddlewareOptionsWithContext(context)
    );
}

export function updateOrganizationContactInfo(
    organizationId: number,
    data: IOrganizationContactInfoUpdate,
    context?: ErrorContext
) {
    return api.post<any>(
        `${serviceUrl}/organization-contact-information/${organizationId}/update`,
        data,
        null,
        getMiddlewareOptionsWithContext(context)
    );
}

export function deleteOrganizationContactInfo(id: number) {
    return api.del<any>(`${serviceUrl}/organization-contact-information/${id}`);
}

export function getOrganizationMembers() {
    const organizationId = sessionStorage.getItem('selectedOrganizationId');
    return api.get<IOrganizationMemberItem[]>(
        `${serviceUrl}/organizations/${organizationId}/resolve-members-with-inheritance`
    );
}

function getM2mOrganizationMembersV2() {
    const organizationId = sessionStorage.getItem('selectedOrganizationId');
    const queryParams = [`memberType=${organizationMemberType.SYSTEM}`];
    return api.get<IGraphQLM2MResponse>(
        `${aggregatorUrl}organization/${organizationId}/members?${queryParams.join('&')}`
    );
}

export function getM2mOrganizationMembers() {
    if (defaultConfig.graphlQL.isDisabled) {
        return getM2mOrganizationMembersV2();
    }
    const query = graphqlQueryBuilder.query([
        {
            operation: 'organizationMembers',
            arguments: {
                organizationId: sessionStorage.getItem('selectedOrganizationId'),
                memberType: organizationMemberType.SYSTEM,
            },
            fields: [
                'id',
                'userId',
                'organizationId',
                'memberType',
                'grantAccessToSubOrganizations',
                {
                    user: ['id', 'name', 'userType'],
                },
                {
                    userStatus: ['id', 'active', 'activationDate', 'deactivationDate', 'deactivationReason'],
                },
                {
                    userCertificates: ['id', 'userId', 'commonName', 'serialNumber', 'expires', 'thumbprint'],
                },
                {
                    organizationMemberContact: ['id', 'organizationMemberId', 'name', 'phoneNumber', 'email'],
                },
            ],
        },
    ]);

    return graphqlRequest<IGraphQLM2MResponse>(query);
}

export function getM2mOrganizationMember(id: number) {
    return api.get<IOrganizationMemberItem>(`${serviceUrl}/organization-members/${id}`);
}

export function createM2mOrganizationMember(data: IM2mOrganizationMemberCreate) {
    return api.post<IIdentifier>(`${serviceUrl}/organization-members/create-m2m-user`, data);
}

export function addMember(data: IOrganizationMemberAdd) {
    return api.post<IOrganizationMemberAddResponse>(`${serviceUrl}/organization-members/add-member`, data);
}

export function deleteOrganizationMemberById(id: number) {
    return api.del<null>(`${serviceUrl}/organization-members/${id}`);
}

export function updateOrganizationMemberContactInfo(data: IOrganizationMemberContactInfo) {
    return api.post<any>(`${serviceUrl}/organization-member-contact-information/${data.id}/update`, data);
}

export function activateOrganizationMember(organizationMemberId: number) {
    return api.put<null>(`${serviceUrl}/organization-members/${organizationMemberId}/activate`, null);
}

export function deactivateOrganizationMember(
    organizationMemberId: number,
    deactivationReason?: organizationMemberDeactivationReason
) {
    return api.put<null>(`${serviceUrl}/organization-members/${organizationMemberId}/deactivate`, {
        deactivationReason: deactivationReason,
    });
}

export function retrieveOnboardingInvitation(token: string) {
    const middlewareOptions: IMiddlewareOptions = {
        errorCodeOptions: { errorCodesHandled: [400, 404, 500] },
    };
    return api.get<IOrganizationOnboardingInvite>(
        `${serviceUrl}/organization-onboarding-invitations/retrieve/${token}`,
        null,
        middlewareOptions
    );
}

export function onboardOrganization(organizationOnboardingData: FormData) {
    return api.postFormData<null>(`${serviceUrl}/organizations/onboarding`, organizationOnboardingData);
}

export function getOnboardingLegalDocuments(token: string, filterData: ILegalDocumentsParameters) {
    const queryParams = [`isLatest=${filterData.isLatest}`, `group=${filterData.group}`, `type=${filterData.type}`];
    return api.get<IResponseCollection<ILegalDocumentItem>>(
        `${serviceUrl}/organization-legal-documents/invitation/${token}?${queryParams.join('&')}`
    );
}

export function getLegalDocuments(params?: ILegalDocumentsParameters) {
    const paramsString = params
        ? `?${Object.keys(params)
              .map((key) => `${key}=${params[key]}`)
              .join('&')}`
        : '';
    return api.get<IResponseCollection<ILegalDocumentItem>>(
        `${serviceUrl}/organization-legal-documents${paramsString}`
    );
}

export function getLegalDocument(id: number) {
    return api.get<ILegalDocumentItem>(`${serviceUrl}/organization-legal-documents/${id}`);
}

export function getAcceptedLegalDocumentsCSV(id: number) {
    return api.get<any>(`${serviceUrl}/organization-legal-documents/${id}/report`);
}

export function createLegalDocument(data: ILegalDocumentData) {
    return api.post<null>(`${serviceUrl}/organization-legal-documents`, data);
}

export function notifyLegalDocuments() {
    return api.post<any>(`${serviceUrl}/accepted-organization-legal-documents/notify-all`, null);
}

export function getAcceptedLegalDocuments(organizationId: number) {
    return api.get<IResponseCollection<IAcceptedLegalDocumentItemExtended>>(
        `${serviceUrl}/accepted-organization-legal-documents?organizationId=${organizationId}` +
            '&lastAccepted=true&$expands=organization-legal-documents&$fields=organizationLegalDocumentId,organization-legal-documents_id,organization-legal-documents_type' +
            ',organization-legal-documents_group,organization-legal-documents_title,organization-legal-documents_version,organization-legal-documents_isLatest'
    );
}

export function acceptLegalDocument(organizationId: number, organizationLegalDocumentId: number) {
    return api.post<null>(`${serviceUrl}/accepted-organization-legal-documents`, {
        organizationId,
        organizationLegalDocumentId,
    });
}

export function createOrganizationOnboardingInvite(data: IOrganizationOnboardingInvite) {
    return api.post<any>(`${serviceUrl}/organization-onboarding-invitations`, data);
}

export function createOrganizationOnboardingInviteBatch(data: Array<IOrganizationOnboardingInvite>) {
    return api.post<any>(`${serviceUrl}/organization-onboarding-invitations/batch`, data);
}

export function getOrganizationRolesCount(organizationId: number) {
    return api.get<IRolesCounts>(
        `${serviceUrl}/organization-member-roles/${organizationId}/get-organizations-members-roles-count`
    );
}

export function createUserTermsSetup(data: IOrganizationUserTermsPayload, context?: ErrorContext) {
    return api.post<any>(`${serviceUrl}/organization-user-terms`, data, null, getMiddlewareOptionsWithContext(context));
}
