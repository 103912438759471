import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPermissions, IRolesPermissionsDictionary } from './interfaces';

const adminPortalSlice = createSlice({
    name: 'adminPortal',
    initialState: {
        selectedOrganizationName: sessionStorage.getItem('selectedOrganizationName'),
        selectedOrganizationId: +sessionStorage.getItem('selectedOrganizationId'),
        userPermissions: [],
        permissionsDictionary: null,
        userPermissionsLoaded: false,
        grantAccessToSubOrganizations: undefined,
        sidebarFeaturesShown: true,
        rolesPermissionsDictionary: null,
    },
    reducers: {
        selectOrganization: (draft, action: PayloadAction<{ orgName: string; orgId: number }>) => {
            const { orgId, orgName } = action.payload;
            sessionStorage.setItem('selectedOrganizationName', orgName);
            sessionStorage.setItem('selectedOrganizationId', orgId.toString());

            draft.selectedOrganizationName = orgName;
            draft.selectedOrganizationId = orgId;
        },
        setUserPermissions: (
            draft,
            action: PayloadAction<{ permissions: number[]; permissionsDictionary: IPermissions }>
        ) => {
            const { permissions, permissionsDictionary } = action.payload;
            draft.userPermissions = permissions;
            draft.permissionsDictionary = permissionsDictionary;
            draft.userPermissionsLoaded = !!action.payload.permissions;
        },
        setRolesPermissionsDictionary: (draft, action: PayloadAction<IRolesPermissionsDictionary>) => {
            const { payload } = action;
            draft.rolesPermissionsDictionary = payload;
        },
        cleanUserPermissions: (draft) => {
            draft.userPermissions = [];
            draft.permissionsDictionary = null;
            draft.userPermissionsLoaded = false;
        },
        setGrantAccessToSubOrganizations: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.grantAccessToSubOrganizations = payload;
        },
        showSidebarFeatures: (draft) => {
            draft.sidebarFeaturesShown = true;
        },
        hideSidebarFeatures: (draft) => {
            draft.sidebarFeaturesShown = false;
        },
    },
});

export const { reducer: adminPortalReducer } = adminPortalSlice;
export const {
    cleanUserPermissions,
    hideSidebarFeatures,
    selectOrganization,
    setGrantAccessToSubOrganizations,
    setRolesPermissionsDictionary,
    setUserPermissions,
    showSidebarFeatures,
} = adminPortalSlice.actions;
export type IAdminPortalState = ReturnType<typeof adminPortalReducer>;
