import { removeProtocol } from '../helpers/regex';

function getApiBaseUrl() {
    const { protocol, hostname } = window.location;
    if (process.env.NODE_ENV === 'production') {
        return `${protocol}//${hostname}`;
    } else {
        if (process.env.REACT_APP_PORTAL !== '' || process.env.REACT_APP_PORTAL !== undefined) {
            switch (process.env.REACT_APP_PORTAL) {
                case 'admin':
                    return process.env.REACT_APP_ADMIN_API_URL;
                case 'organisation':
                    return process.env.REACT_APP_ORGANIZATION_API_URL;
                default:
                    return process.env.REACT_APP_USER_API_URL;
            }
        }

        if (process.env.NODE_ENV === 'development') {
            if (hostname.indexOf('admin-localhost') >= 0) {
                return process.env.REACT_APP_ADMIN_API_URL;
            }
            if (hostname.indexOf('organisation-localhost') >= 0) {
                return process.env.REACT_APP_ORGANIZATION_API_URL;
            }
        }

        return process.env.REACT_APP_USER_API_URL;
    }
}

export const staticConfig = {
    apiBaseUrl: getApiBaseUrl(),
    appHost: getApiBaseUrl()?.replace(removeProtocol, ''),
    appBaseUrl: `${window.location.protocol}//${window.location.host}`,
    auth: {
        scopes: [
            'openid',
            'profile',
            'offline_access',
            'staticresourceservice',
            'dataexportservice',
            'senderservice',
            'subscriptionservice',
            'offline_access',
            'organizationservice',
            'globalizationservice',
            'graphqlservice',
            'businesseventlogservice',
            'portalservice',
            'openid',
            'artifactservice',
            'contactservice',
            'emfmessageprocessorservice',
            'mailboxservice',
            'messageroutingservice',
            'messageservice',
            'notificationservice',
            'receiptservice',
            'restmessagereceiverservice',
            'securityconfigurationservice',
            'signingservice',
            'signingproviderintegrationservice',
            'statisticsservice',
            'transactionservice',
            'transmissionstatusservice',
            'useridentityservice',
            'userservice',
            'featureservice',
            'quotaservice',
            'keymanagementservice',
            'messageforwardservice',
            'privilegeservice',
            'usersettingsservice',
            'aggregatorservice',
            'onboardingservice',
        ],
        anonymousRoutes: ['/verify/email', '/unverify/email', '/activate-password', '/onboarding'],
    },
    validations: {
        messageTitleMaxLength: 100,
    },
    settings: {
        adminPortal: {
            maxNumberOfCsvErrorsToShow: 5,
        },
    },
    maxStatisticsDataDotsAmount: 50,
    defaultTitle: 'Digital Postbox',
};
