import { mode, transparentize } from '@chakra-ui/theme-tools';
import type { SystemStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
    lineHeight: 'normal',
    borderRadius: 'full',
    fontWeight: 'semibold',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    flexShrink: 0,
    _focus: {
        boxShadow: 'outline',
    },
    _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none',
    },
    _active: {
        borderRadius: 'full',
    },
    _focusVisible: {
        borderRadius: 'full',
    },
    _hover: {
        _disabled: {
            bg: 'initial',
        },
    },
};

const variantSolid: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;

    const background = mode(`${c}.600`, `${c}.50`)(props);

    return {
        bg: background,
        color: mode('white', `gray.800`)(props),
        _hover: {
            bg: mode(`${c}.400`, `${c}.100`)(props),
            _disabled: {
                bg: background,
            },
        },
        _focus: {
            borderRadius: 'full',
        },
        _focusVisible: {
            bg: mode(`${c}.400`, `${c}.100`)(props),
        },
        _active: {
            borderRadius: 'full',
            bg: mode(`${c}.500`, `${c}.200`)(props),
        },
    };
};

export const variantPrimary: SystemStyleFunction = () => {
    const bg = 'linear(to-r, red.600, pink.700)';

    return {
        bgGradient: bg,
        color: 'white',
        lineHeight: 'normal',
        verticalAlign: 'baseline',
        _active: {
            bgGradient: bg,
        },
        _hover: {
            bgGradient: 'linear(to-r, red.700, pink.800)',
            _disabled: {
                bgGradient: bg,
            },
        },
    };
};

export const variantGhost: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const dark = transparentize(`${c}.50`, 0.6)(props);
    return {
        color: mode(`${c}.600`, `${c}.100`)(props),
        lineHeight: 'normal',
        verticalAlign: 'baseline',
        _hover: {
            bg: mode(`${c}.50`, dark)(props),
        },
    };
};

export const variantOutline: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        color: `${c}.600`,
        lineHeight: 'normal',
        verticalAlign: 'baseline',
        borderWidth: '1px',
        borderColor: `${c}.600`,
        _hover: {
            bg: `${c}.50`,
        },
    };
};

const variantUnstyled: SystemStyleObject = {
    bg: 'none',
    color: 'inherit',
    display: 'inline',
    lineHeight: 'inherit',
    m: 0,
    p: 0,
};

const variants = {
    primary: variantPrimary,
    solid: variantSolid,
    ghost: variantGhost,
    outline: variantOutline,
    unstyled: variantUnstyled,
};

const sizes = {
    lg: {
        h: 12,
        minW: 12,
        fontSize: 'md',
        px: 6,
    },
    md: {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
    },
    sm: {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 3,
    },
};

const defaultProps = {
    size: 'md',
    variant: 'solid',
    colorScheme: 'red',
};

export const Button = {
    baseStyle,
    variants,
    sizes,
    defaultProps,
};
