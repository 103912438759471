import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        sidebarShown: false,
        editFolderModeActive: false,
    },
    reducers: {
        showSidebar: (draft) => {
            draft.sidebarShown = true;
        },
        hideSidebar: (draft) => {
            draft.sidebarShown = false;
        },
        toggleSidebar: (draft) => {
            draft.sidebarShown = !draft.sidebarShown;
        },
        editFolderMode: (draft, action: PayloadAction<boolean>) => {
            const { payload } = action;
            draft.editFolderModeActive = payload;
        },
    },
});

export const { reducer: sidebarReducer } = sidebarSlice;
export const { editFolderMode, hideSidebar, showSidebar, toggleSidebar } = sidebarSlice.actions;

export type ISidebarState = ReturnType<typeof sidebarReducer>;
