import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IArtifact } from '../../interfaces/artifact.interfaces';
import { IMessage } from '../../interfaces/message.interfaces';

const documentPreviewSlice = createSlice({
    name: 'documentPreview',
    initialState: {
        document: undefined,
        documentArtifacts: undefined,
        activeArtifact: undefined,
        activePopupArtifact: undefined,
        olderThreadDocuments: undefined,
        previewShown: undefined,
        requireOpenReceipt: undefined,
    },
    reducers: {
        initiateDocumentPreview: (
            draft,
            action: PayloadAction<{
                document: IMessage;
                artifacts: Array<IArtifact>;
                activeArtifact?: IArtifact;
                olderThreadDocuments?: IMessage[];
                requireOpenReceipt?: boolean;
            }>
        ) => {
            const { document, artifacts, activeArtifact, olderThreadDocuments, requireOpenReceipt } = action.payload;

            draft.document = document;
            draft.documentArtifacts = artifacts;
            draft.activeArtifact = activeArtifact ? activeArtifact : null;
            draft.olderThreadDocuments = olderThreadDocuments ? olderThreadDocuments : null;
            draft.requireOpenReceipt = requireOpenReceipt;
        },
        showDocumentPreview: (draft) => {
            draft.previewShown = true;
        },
        hideDocumentPreview: (draft) => {
            draft.previewShown = false;
        },
        clearDocumentPreview: (draft) => {
            draft.document = undefined;
            draft.documentArtifacts = undefined;
            draft.activeArtifact = undefined;
            draft.olderThreadDocuments = undefined;
            draft.previewShown = undefined;
            draft.requireOpenReceipt = undefined;
        },
        updatePreviewDocument: (draft, action: PayloadAction<IMessage>) => {
            const { payload } = action;
            draft.document = payload;
        },
        previewArtifact: (draft, action: PayloadAction<IArtifact>) => {
            const { payload } = action;
            draft.activeArtifact = payload;
        },
        previewPopupArtifact: (draft, action: PayloadAction<IArtifact>) => {
            const { payload } = action;
            draft.activePopupArtifact = payload;
        },
        hideSendOpenReceipt: (draft, action: PayloadAction<Array<number>>) => {
            const { payload } = action;
            draft?.document?.artifacts.forEach((artifact) => {
                if ((payload && payload.includes(artifact.id)) || !payload) {
                    artifact.sendOpenReceipt = false;
                    draft.requireOpenReceipt = false;
                }
            });
        },
    },
});

export const { reducer: documentPreviewReducer } = documentPreviewSlice;
export const {
    clearDocumentPreview,
    hideDocumentPreview,
    initiateDocumentPreview,
    previewArtifact,
    previewPopupArtifact,
    showDocumentPreview,
    updatePreviewDocument,
    hideSendOpenReceipt,
} = documentPreviewSlice.actions;
export type IDocumentPreviewState = ReturnType<typeof documentPreviewReducer>;
