/**
 * Theme colors
 */
export const colors = {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000000',
    white: '#FFFFFF',

    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)',
    },

    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0, 0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)',
    },

    coolGray: {
        50: '#F0F4F6',
        100: '#D3DEE5',
        200: '#B6C9D4',
        300: '#99B3C2',
        400: '#7C9DB1',
        500: '#5F88A0',
        600: '#4E6F83',
        700: '#3D5666',
        800: '#223944',
        900: '#1A252C',
    },

    gray: {
        50: '#F2F4F5',
        100: '#D5E0E3',
        200: '#BDC8CD',
        300: '#A3B2B9',
        400: '#7F9AA3',
        500: '#6E8791',
        600: '#5A6E77',
        700: '#46565C',
        800: '#323D42',
        900: '#1E2528',
    },

    red: {
        50: '#FBECED',
        100: '#F3C6C9',
        200: '#EBA0A5',
        300: '#E27981',
        400: '#DA535D',
        500: '#D22D3A',
        600: '#A8242E',
        700: '#861D25',
        800: '#5F141A',
        900: '#390C10',
    },

    yellow: {
        50: '#FEF5E6',
        100: '#FCE2B5',
        200: '#FAC974',
        300: '#F9BC53',
        400: '#F7A921',
        500: '#DE8F08',
        600: '#C98207',
        700: '#9D6506',
        800: '#704804',
        900: '#432B02',
    },

    green: {
        50: '#F0FBEC',
        100: '#D1F3C5',
        200: '#B2EB9F',
        300: '#94E378',
        400: '#75DC52',
        500: '#56D42B',
        600: '#47AD23',
        700: '#3C931E',
        800: '#276014',
        900: '#183A0C',
    },

    blue: {
        50: '#EAF2FD',
        100: '#BFD8F9',
        200: '#94BEF6',
        300: '#6AA4F2',
        400: '#3F8AEE',
        500: '#1470EB',
        600: '#115BC0',
        700: '#0D4795',
        800: '#09336B',
        900: '#061E40',
    },

    cyan: {
        50: '#E8F6FA',
        100: '#C6E9F3',
        200: '#A0DAEA',
        300: '#7ACBE2',
        400: '#54BCDA',
        500: '#2EADD1',
        600: '#258EAB',
        700: '#1D6E85',
        800: '#154F5F',
        900: '#0C2F39',
    },

    turquis: {
        50: '#F0F7F6',
        100: '#D2E6E4',
        200: '#B4D6D2',
        300: '#96C5C0',
        400: '#78B5AE',
        500: '#5FA79F',
        600: '#4A8780',
        700: '#3A6963',
        800: '#294B47',
        900: '#192D2B',
    },

    pink: {
        50: '#F9EEF4',
        100: '#EDCBDD',
        200: '#E2A8C7',
        300: '#D686B1',
        400: '#CA639A',
        500: '#BE4184',
        600: '#9C356C',
        700: '#7C2A56',
        800: '#571D3C',
        900: '#341224',
    },
};
