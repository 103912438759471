import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stringify } from '../../helpers/queryString';
import { staticConfig } from '../../config/static';
import { clearAuthStorage } from '../../helpers/auth';

const buildQueryAndRedirect = (isDeletion?: boolean) => {
    const query = stringify({
        id_token_hint: sessionStorage.getItem('id_token'),
        post_logout_redirect_uri: staticConfig.appBaseUrl,
        ...(isDeletion ? { deletion_requested: true } : null),
    });

    document.getElementById('root').classList.value = 'd-none'; // Safari fix to wipe page visibility due to page caching
    clearAuthStorage();
    document.getElementById('root').classList.value = 'd-none'; // Safari fix to wipe page visibility due to page caching
    window.location.href = `${staticConfig.apiBaseUrl}/loginservice/connect/endsession?${query}`;
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        idToken: sessionStorage.getItem('id_token'),
        accessToken: sessionStorage.getItem('token'),
        TokenExpiry: sessionStorage.getItem('tokenExp'),
    },
    reducers: {
        setToken: (draft, action: PayloadAction<string>) => {
            const { payload } = action;
            draft.accessToken = payload;
        },
        logoutUser: () => {
            buildQueryAndRedirect();
        },
        deleteUser: () => {
            buildQueryAndRedirect(true);
        },
    },
});

export const { reducer: authReducer } = authSlice;
export const { deleteUser, logoutUser, setToken } = authSlice.actions;

export type IAuthState = ReturnType<typeof authReducer>;
