import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
    width: '100%',
    height: '10px',
    borderRadius: '5px',
    filledTrack: {
        bgGradient: 'linear(to-r, red.600, pink.700)',
    },
};

export const Progress = {
    baseStyle,
};
