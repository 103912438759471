import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import type { PartsStyleObject, PartsStyleFunction, StyleFunctionProps } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
    container: {
        px: 4,
        py: 3,
    },
    title: {
        fontWeight: 'bold',
        lineHeight: 6,
        marginEnd: 2,
    },
    description: {
        lineHeight: 6,
    },
    icon: {
        flexShrink: 0,
        marginEnd: 3,
        w: 5,
        h: 6,
    },
};

function getBg(props: StyleFunctionProps): string {
    const { theme, colorScheme: c } = props;
    const lightBg = getColor(theme, `${c}.100`, c);
    const darkBg = transparentize(`${c}.200`, 0.16)(theme);
    return mode(lightBg, darkBg)(props);
}

const variantSubtle: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props;
    return {
        container: {
            bg: getBg(props),
            borderRadius: '2xl',
        },
        icon: {
            color: mode(`${c}.500`, `${c}.200`)(props),
        },
    };
};

const variantSolid: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props;
    return {
        container: {
            bg: mode(`${c}.500`, `${c}.200`)(props),
            color: mode(`white`, `gray.900`)(props),
            borderRadius: '2xl',
        },
    };
};

const variants = {
    subtle: variantSubtle,
    solid: variantSolid,
};

const defaultProps = {
    variant: 'subtle',
    colorScheme: 'blue',
};

export const Alert = {
    parts: parts.keys,
    baseStyle,
    variants,
    defaultProps,
};
