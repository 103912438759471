import { getObjDeepCopy } from '../helpers/general';
import { IBrandingLogo, IBrandingLink, IBrandingColor, IBrandingText } from '../interfaces/portal.interfaces';
import { IPortalLink, IPortalLogo } from '../interfaces/config.interfaces';

export function ComposeColorVariables(brandingColors: Array<IBrandingColor>): Map<string, string> {
    const colorVariables = new Map<string, string>();
    brandingColors
        .filter((item) => item.code.startsWith('--'))
        .forEach((item) => {
            const color = item.color || item.defaultColor;
            colorVariables.set(item.code, EnrichColorWithHashSignPrefix(color));
        });

    return colorVariables;
}

export function ComposeBrandingLinks(brandingLinks: Array<IBrandingLink>, languageId: number): Array<IPortalLink> {
    return brandingLinks.map((bLink) => {
        const linkTranslation = bLink.linkTranslations.find((link) => link.languageId === languageId); // find correct translation
        return {
            code: bLink.code,
            defaultLink: bLink?.defaultLink, // Need to remove this default link as part of cleanup process.
            link: linkTranslation ? linkTranslation.link : '',
            label: linkTranslation ? linkTranslation.label : '',
            isActivated: linkTranslation ? linkTranslation?.isActivated : false,
            translationKey: bLink.defaultLabelTranslationKey,
        } as IPortalLink;
    });
}

export function ComposeBrandingLogos(brandingLogos: Array<IBrandingLogo>): Array<IPortalLogo> {
    return brandingLogos.map((bLogo) => {
        return {
            code: bLogo.code,
            imageId: bLogo.imageId > 0 ? bLogo.imageId : bLogo.defaultLogoId,
        } as IPortalLogo;
    });
}

export function ComposeBrandingTexts(brandingTexts: Array<IBrandingText>, defaultTextsObj: object, languageId: number) {
    const resultLogosObj = getObjDeepCopy(defaultTextsObj);
    Object.keys(defaultTextsObj).forEach((dTextKey) => {
        resultLogosObj[dTextKey] = { ...resultLogosObj[dTextKey], ...TextMapper(brandingTexts, dTextKey, languageId) };
    });
    return resultLogosObj;
}

export function ComposePortalFeatures(portalFeatures: any, defaultFeaturesObj: any) {
    const resultPortalFeatures = getObjDeepCopy(defaultFeaturesObj);
    Object.keys(defaultFeaturesObj).forEach((dFeatureKey) => {
        resultPortalFeatures[dFeatureKey] = {
            ...resultPortalFeatures[dFeatureKey],
            ...PortalFeatureMapper(portalFeatures, dFeatureKey),
        };
    });

    return resultPortalFeatures;
}

function TextMapper(items: Array<IBrandingText>, property: string, languageId: number): any {
    const properties = {};
    items
        .filter((item: IBrandingText) => {
            return item.code.indexOf(`${property}.`) > -1; // filter items by prefix match
        })
        .forEach((item: IBrandingText) => {
            let code = item.code;
            code = code.substring(code.indexOf('.') + 1); // remove prefix

            const textTranslation = item.textTranslations.find((link) => link.languageId === languageId); // find correct translation
            const text = textTranslation ? textTranslation.text : '';
            const { defaultTranslationKey } = item;
            if (code.indexOf('.') > -1) {
                const codes = code.split('.');
                properties[codes[0]] = properties[codes[0]] || {};
                properties[codes[0]][codes[1]] = {
                    defaultTranslationKey,
                    text,
                };
            } else {
                properties[code] = {
                    defaultTranslationKey,
                    text,
                };
            }
        });
    return properties;
}

function PortalFeatureMapper(items: Array<any>, property: string): any {
    const properties = {};
    items
        .filter((item: any) => {
            return item._expands[Object.keys(item._expands)[0]].code.indexOf(`${property}.`) > -1; // filter items by prefix match
        })
        .forEach((item: any) => {
            let code = item._expands[Object.keys(item._expands)[0]].code;
            code = code.substring(code.indexOf('.') + 1);
            if (code.indexOf('.') > -1) {
                code = code.split('.');
                properties[code[0]] = properties[code[0]] || {};
                properties[code[0]][code[1]] = true;
            } else {
                properties[code] = true;
            }
        });
    return properties;
}

function EnrichColorWithHashSignPrefix(colorValue: string): string {
    return colorValue.startsWith('#') ? colorValue : `#${colorValue}`;
}
