import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';
import type { IUserSettings, IAcceptOpenReceiptUserSettings } from '../../../../interfaces';

const baseUrl = staticConfig.apiBaseUrl + '/usersettingsservice';

const tagTypes = ['UserSettings'];

export const userSettingsApi = createApi({
    reducerPath: 'userSettingsApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getUserSettings: builder.query<IUserSettings, void>({
            query: () => `/user/${sessionStorage.getItem('userId')}/settings`,
            providesTags: tagTypes,
        }),
        updateOpenReceiptUserSettings: builder.mutation({
            query: ({ alwaysAcceptOpenReceipt }: IAcceptOpenReceiptUserSettings) => ({
                url: `/user/${sessionStorage.getItem('userId')}/settings/always-accept-open-receipt`,
                body: {
                    alwaysAcceptOpenReceipt,
                },
                method: 'PUT',
            }),
            invalidatesTags: tagTypes,
        }),
    }),
});

export const { useGetUserSettingsQuery, useLazyGetUserSettingsQuery, useUpdateOpenReceiptUserSettingsMutation } =
    userSettingsApi;
