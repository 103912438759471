import { defaultConfig } from '../config/default.config';

/**
 * Convert object to type representation of defaultConfig
 */
type Features = typeof defaultConfig.features;

/**
 * Recursively through all properties in a nested object structure
 * and join the keys in to template literals
 *
 * e.g. { prop: {one: false } } becomes 'prop.one'
 *
 */
type Access<T, Prefix extends string = ''> = T extends string | number | bigint | boolean | null | undefined
    ? never
    : {
          [K in keyof T & string]: T[K] extends object ? Access<T[K], `${Prefix}${K}.`> : `${Prefix}${K}`;
      }[keyof T & string];

/**
 * All available features in a Union type
 */
export type AvailableFeatures = Access<Features>;

/**
 * Located the value of the provided key in the feature configuration
 *
 * use this to limit features from the users
 */
export const determineFeatureAccess = (features: Features, feature: AvailableFeatures): boolean => {
    const keys = feature.split('.');

    const getValue = (obj: object, keys: string[]) => {
        const rootKey = keys.slice(0, 1);

        if (rootKey.length === 0 || !obj) {
            return;
        }
        const val = obj[rootKey[0]];
        if (typeof val !== 'object') {
            return val;
        }

        return getValue(val, keys.splice(1));
    };

    const locatedFeature = getValue(features, keys);

    if (locatedFeature === undefined) {
        // pessimistic fallback in case a feature configuration is not provided.
        return false;
    }

    return locatedFeature;
};
