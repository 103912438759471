import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { radii } from './radii';
import { semanticTokens } from './tokens';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { space } from './space';
import { typography } from './typography';
import { zIndices } from './zIndicies';

/**
 * Theme foundation
 */
export const foundations = {
    ...typography,
    breakpoints,
    colors,
    radii,
    semanticTokens,
    shadows,
    sizes,
    space,
    zIndices,
};
