import React from 'react';
// Styles
import styles from './loader.module.scss';

interface ILoaderAnimation extends React.HTMLProps<SVGElement> {
    className: string;
    fill?: string;
}

const LoaderAnimation = (props: ILoaderAnimation) => {
    return (
        <svg
            className={props.className || ''}
            id="enhxo8qehcq1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
        >
            <rect
                id="enhxo8qehcq2"
                display="none"
                width="100"
                height="100"
                rx="0"
                ry="0"
                fill="rgb(196,196,196)"
                stroke="none"
                strokeWidth="1"
            />
            <rect
                id="enhxo8qehcq3"
                display="none"
                width="64"
                height="64"
                rx="0"
                ry="0"
                transform="matrix(1 0 0 1 18 18)"
                fill="rgb(196,196,196)"
                stroke="none"
                strokeWidth="1"
            />
            <g className={styles.enhxo8qehcq4_tr} id="enhxo8qehcq4_tr" transform="translate(50,50) rotate(0)">
                <g id="enhxo8qehcq4" transform="translate(-50,-50)">
                    <g className={styles.enhxo8qehcq5_to} id="enhxo8qehcq5_to" transform="translate(23,-23)">
                        <circle
                            id="enhxo8qehcq5"
                            r="9.500000"
                            transform="translate(27.500000,72.500000)"
                            fill={props.fill || 'rgb(168,36,46)'}
                            stroke="none"
                            strokeWidth="1"
                        />
                    </g>
                    <g className={styles.enhxo8qehcq6_to} id="enhxo8qehcq6_to" transform="translate(-22,-23)">
                        <circle
                            id="enhxo8qehcq6"
                            r="9.500000"
                            transform="translate(72.500000,72.500000)"
                            fill={props.fill || 'rgb(168,36,46)'}
                            stroke="none"
                            strokeWidth="1"
                        />
                    </g>
                    <g className={styles.enhxo8qehcq7_to} id="enhxo8qehcq7_to" transform="translate(-22,22)">
                        <circle
                            id="enhxo8qehcq7"
                            r="9.500000"
                            transform="translate(72.500000,27.500000)"
                            fill={props.fill || 'rgb(168,36,46)'}
                            stroke="none"
                            strokeWidth="1"
                        />
                    </g>
                    <g className={styles.enhxo8qehcq8_to} id="enhxo8qehcq8_to" transform="translate(23,22)">
                        <circle
                            id="enhxo8qehcq8"
                            r="9.500000"
                            transform="translate(27.500000,27.500000)"
                            fill={props.fill || 'rgb(168,36,46)'}
                            stroke="none"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
            <circle
                id="enhxo8qehcq9"
                display="none"
                r="9.500000"
                transform="matrix(1 0 0 1 50.50000000000000 49.50000000000000)"
                fill="rgb(0,0,0)"
                fillOpacity="0.9"
                stroke="none"
                strokeWidth="1"
            />
        </svg>
    );
};

export default LoaderAnimation;
