export enum LegalDocumentTypes {
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    DATA_PROCESSOR_AGREEMENT = 'DataProcessorAgreement',
    DIBS_PAYMENT_AGREEMENT = 'DibsPaymentAgreement',
    PBS_PAYMENT_AGREEMENT = 'PbsPaymentAgreement',
    MARKETING_MATERIAL_AGREEMENT = 'MarketingMaterialAgreement',
}

export enum LegalDocumentTypesEnum {
    TERMS_AND_CONDITIONS,
    DATA_PROCESSOR_AGREEMENT,
    DIBS_PAYMENT_AGREEMENT,
    PBS_PAYMENT_AGREEMENT,
    MARKETING_MATERIAL_AGREEMENT,
}

export enum LegalDocumentGroupsEnum {
    PRIVATE,
    PUBLIC,
}

export enum LegalDocumentsEmum {
    TermsAndConditions,
    UserAgreement,
    PrivacyStatement,
    LegalDocument,
    UserTermsDocuments,
}
