import moment from 'moment';
import { store } from 'src/redux/store';
import { hybridClientIds } from 'src/enums/portal';
import { staticConfig } from '../config/static';
import { parseQueryString, stringify } from './queryString';

export function shouldSkipAuth() {
    return staticConfig.auth.anonymousRoutes.includes(window.location.pathname);
}

export function setTokenExpiration(expiresIn: number) {
    sessionStorage.setItem('tokenExp', moment().add(expiresIn, 'seconds').subtract('1', 'minutes').toISOString());
}

export function loginRedirect() {
    const { portal } = store.getState();

    const loginLocales = parseQueryString(window.location.search).login_locales;

    const query = stringify({
        client_id: hybridClientIds[portal.portalType],
        redirect_uri: staticConfig.appBaseUrl,
        response_type: 'code id_token',
        scope: staticConfig.auth.scopes.join(' '),
        nonce: Date.now(),
        ...(loginLocales && { ui_locales: loginLocales }),
    });

    window.location.href = `${staticConfig.apiBaseUrl}/loginservice/connect/authorize?${query}`;
}

export function clearAuthStorage() {
    window.sessionStorage.clear();
    window.localStorage.removeItem('mailboxId');
    window.localStorage.removeItem('selectedOrganizationName');
    window.localStorage.removeItem('selectedOrganizationId');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('userWasCreated');
    window.localStorage.removeItem('isNewLegalDocumentsPopupShown');
}

export function isAuthenticated() {
    return !!sessionStorage.getItem('userId');
}
