import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { getToken } from '../middleware/helpers';

export type PrepareHeadersFunc = (
    headers: Headers,
    api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => Headers;

/**
 * Appends the accessToken to the request Headers
 *
 * @summary
 * headers.set('authorization', `Bearer ${accessToken}`);
 *
 * @param headers
 * @param param1
 * @returns
 */
export const prepareHeaders: PrepareHeadersFunc = (headers, { getState }) => {
    const accessToken = sessionStorage.getItem('token') || getToken();

    if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
};
