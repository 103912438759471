// Interfaces
import { IError, IFormattedMessageProps, IKeyValuePair, ITranslateKeyError } from '../interfaces/general.interfaces';
//  Enums
import { ErrorContext } from '../enums/context';
// Components
import { errorCodes as filePickerErrorCodes } from '../components/form/FilePicker';

interface IErrorCodes {
    [code: string]: IErrorCodeDefinition;
}

interface IErrorCodeDefinition {
    defaultKey: string;
    componentClasses?: Array<IErrorCodeComponent>;
}

interface IErrorCodeComponent {
    context: ErrorContext;
    contextDefaultKey: string;
    properties?: IKeyValuePair;
}

export function getErrorTextProps(errors: IError[], defaultErrorText = 'ERROR'): IFormattedMessageProps[] {
    if (!errors) {
        return [];
    }

    return errors.map((error: IError) => ({ id: getErrorTextByCode(error.errorCode, defaultErrorText) }));
}

export function getErrorTextByCode(errorCode: number, defaultErrorText = 'ERROR'): string {
    const contextErrorCode = errorCodes[errorCode];

    return (contextErrorCode && contextErrorCode.defaultKey) || defaultErrorText;
}

export function getTranslateErrorKey(
    errorCode: number,
    propertyName: string,
    context: ErrorContext
): ITranslateKeyError {
    const errorFound = errorCodes[errorCode];
    let errorKey = null;

    if (errorFound) {
        errorKey = errorFound.defaultKey;
        if (errorFound.componentClasses && context) {
            const compClassFound = errorFound.componentClasses.find((errorFound) => {
                return errorFound.context === context;
            });

            if (compClassFound) {
                if (propertyName && compClassFound.properties) {
                    const foundKey = compClassFound.properties[propertyName];
                    if (foundKey) {
                        return { key: foundKey.toString(), propertyName: propertyName };
                    }
                } else {
                    if (compClassFound.contextDefaultKey) {
                        return { key: compClassFound.contextDefaultKey, propertyName: propertyName };
                    }
                }
            }
        }
    }

    return { key: errorKey, propertyName: propertyName };
}

export const errorCodes: IErrorCodes = {
    1001: {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.USERS_SCREEN,
                contextDefaultKey: 'USER_NOT_FOUND',
                properties: null,
            },
            {
                context: ErrorContext.UPDATE_FOLDER,
                contextDefaultKey: 'FOLDER_NOT_FOUND',
                properties: null,
            },
            {
                context: ErrorContext.DELETE_FOLDER,
                contextDefaultKey: 'FOLDER_NOT_FOUND',
                properties: null,
            },
        ],
    },
    1027: {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.ORGANIZATION_FORM,
                contextDefaultKey: null,
                properties: {
                    phoneNumber: 'PHONE_NUMBER_IS_REQUIRED',
                    name: 'CONTACT_NAME_IS_REQUIRED',
                    email: 'EMAIL_IS_REQUIRED',
                },
            },
        ],
    },
    2002: {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.SENDER_FORM,
                contextDefaultKey: 'SENDER_IS_NOT_UNIQUE',
                properties: null,
            },
            {
                context: ErrorContext.UPDATE_FOLDER,
                contextDefaultKey: 'FOLDER_NAME_ALREADY_EXISTS',
                properties: null,
            },
            {
                context: ErrorContext.CREATE_FOLDER,
                contextDefaultKey: 'FOLDER_NAME_ALREADY_EXISTS',
                properties: null,
            },
            {
                context: ErrorContext.ORGANIZATION_FORM,
                contextDefaultKey: 'SAME_IP_ADDRESS',
                properties: null,
            },
            {
                context: ErrorContext.PROFILE_FORM,
                contextDefaultKey: 'TEXT_PROFILE_EMAILS_EQUALITY_WARNING',
                properties: null,
            },
            {
                context: ErrorContext.USER_TERMS,
                contextDefaultKey: 'USER_TERMS_DOCUMENTS_POPUP_VERSION_ERROR',
                properties: null,
            },
        ],
    },
    13001: { defaultKey: 'ERROR_NO_ORGANIZATION_ID' },
    13002: { defaultKey: 'ERROR_NO_LANGUAGE_ID' },
    13003: { defaultKey: 'ERROR_NO_COUNTRY_ID' },
    13004: { defaultKey: 'ERROR_NO_TERMS_ID' },
    13200: { defaultKey: 'INVALID_PASSWORD' },
    13400: { defaultKey: 'LINK_EXPIRED' },
    13502: { defaultKey: 'ACTIVATE_PASSWORD_TOO_MANY_ATTEMPTS' },
    13503: { defaultKey: 'ACTIVATE_PASSWORD_INVALID_ATTEMPT' },
    13520: { defaultKey: 'INVALID_CURRENT_PASSWORD' },
    13530: { defaultKey: 'NEW_PASSWORD_SHOULD_BE_DIFFERENT' },
    // ContactService
    15400: { defaultKey: 'EMAIL_NOT_FOUND' },
    15410: { defaultKey: 'MAIL_ADDRESS_IS_NOT_VALID' },
    15510: { defaultKey: 'NUMBER_ALREADY_EXISTS' },
    15530: { defaultKey: 'ERROR_INVALID_MOBILENUMBER' },
    15620: { defaultKey: 'EMAIL_VERIFICATION_CODE_EXPIRED' },
    15630: { defaultKey: 'SIGNUP_CONFIRM_SMSCODENOTVALID' },
    15635: { defaultKey: 'EMAIL_VERIFICATION_CODE_WRONG' },
    15640: { defaultKey: 'PHONE_IS_ALREADY_VERIFIED' },
    15650: { defaultKey: 'EXCEED_NUMBER_PHONE_VERIFICATIONS' },
    15610: {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.UNVERIFY_EMAIL,
                contextDefaultKey: 'VERIFICATION_CODE_HAS_NOT_BEEN_REQUESTED',
                properties: null,
            },
            {
                context: ErrorContext.VERIFY_MOBILE,
                contextDefaultKey: 'VERIFICATION_CODE_HAS_NOT_BEEN_REQUESTED_MOBILE',
                properties: null,
            },
        ],
    },
    // UserIdentityService
    17040: { defaultKey: 'USERNAME_ALREADY_EXISTS' },
    17051: { defaultKey: 'USER_ALREADY_EXISTS' },
    // MessageService
    18005: getMessageServiceErrorComponent(),
    18301: getMessageServiceErrorComponent(),
    18302: getMessageServiceErrorComponent(),
    18303: getMessageServiceErrorComponent(),
    // MailboxService
    20001: getMailboxServiceErrorComponent(),
    20002: getMailboxServiceErrorComponent(),
    20050: {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.CREATE_FOLDER,
                contextDefaultKey: 'FOLDER_CREATE_NAME_EMPTY_OR_INVALID',
                properties: null,
            },
            {
                context: ErrorContext.UPDATE_FOLDER,
                contextDefaultKey: 'FOLDER_RENAME_NAME_EMPTY_OR_INVALID',
                properties: null,
            },
        ],
    },
    20100: { defaultKey: 'FOLDER_NAME_TOO_LONG' },
    20402: getMailboxServiceErrorComponent(),
    20501: { defaultKey: 'FOLDER_SAME_FOLDER_SELECTED' },
    20502: getMailboxServiceErrorComponent(),
    20503: getMailboxServiceErrorComponent(),
    20504: getMailboxServiceErrorComponent(),
    20611: { defaultKey: 'ACCESS_ALREADY_EXIST' },
    20612: { defaultKey: 'ACCESS_USER_NOT_FOUND' },
    37005: { defaultKey: 'INVALID_IP_ADDRESS' },
    // MessageForwardService
    23010: { defaultKey: 'FORWARDING_STATUS_FAIL' },
    23070: { defaultKey: 'FORWARDING_STATUS_FAIL' },
    41010: { defaultKey: 'FAILED_TO_SEND_THE_FORM' },
    41020: { defaultKey: 'FAILED_TO_SEND_THE_FORM' },
    41030: { defaultKey: 'FAILED_TO_SEND_THE_FORM' },
    41040: { defaultKey: 'FAILED_TO_SEND_THE_FORM' },
    41050: { defaultKey: 'FAILED_TO_SEND_THE_FORM' },
    // OrganizationService
    25003: { defaultKey: 'ORG_MEMBER_EMAIL_FORMAT_INCORRECT' },
    25015: { defaultKey: 'USER_EXISTS' },
    25016: { defaultKey: 'EMPLOYEE_EXISTS_IN_ORG' },
    25017: { defaultKey: 'ORG_ISNT_CREATED' },
    25018: { defaultKey: 'ORG_ID_GREATER' },
    25019: { defaultKey: 'USERNAME_SHOUDNT_BE_EMPTY' },
    25020: { defaultKey: 'ORG_MEMBER_CREATE_NOT_ALLOWED' },
    25027: { defaultKey: 'ORG_MEMBER_CERTIFICATE_EMPTY' },
    // QuotaService
    40005: { defaultKey: 'NEW_QUOTA_EXCEEDED' },
};

export function mapFileUploadErrors(
    errors: filePickerErrorCodes[],
    fileSize?: number,
    fileTypes?: string
): IFormattedMessageProps[] {
    return errors.map((error) => {
        switch (error) {
            case filePickerErrorCodes.FILE_TYPES_INVALID:
                return { id: 'FILE_TYPES_INVALID', values: { types: fileTypes || 'TIF, GIF, BMP, PNG or JPG' } };
            case filePickerErrorCodes.FILE_SIZE_INVALID:
                return { id: 'FILE_SIZE_INVALID', values: { size: fileSize || 100 } };
            default:
                return { id: 'ERROR' };
        }
    });
}

function getMessageServiceErrorComponent(): IErrorCodeDefinition {
    return {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.MOVE_MESSAGES,
                contextDefaultKey: 'UNABLE_TO_MOVE_MAILS',
                properties: null,
            },
            {
                context: ErrorContext.TRASH_MESSAGES,
                contextDefaultKey: 'UNABLE_TO_DELETE_MAILS',
                properties: null,
            },
        ],
    };
}

function getMailboxServiceErrorComponent(): IErrorCodeDefinition {
    return {
        defaultKey: null,
        componentClasses: [
            {
                context: ErrorContext.CREATE_FOLDER,
                contextDefaultKey: 'UNABLE_TO_CREATE_FOLDER',
                properties: null,
            },
            {
                context: ErrorContext.UPDATE_FOLDER,
                contextDefaultKey: 'UNABLE_TO_RENAME_FOLDER',
                properties: null,
            },
        ],
    };
}
