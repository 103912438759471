import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { portalType } from '../../enums/portal';

const portalSlice = createSlice({
    name: 'portal',
    initialState: {
        portalId: undefined,
        portalType: undefined,
        portalOrganizationId: undefined,
    },
    reducers: {
        setPortalId: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            draft.portalId = payload;
        },
        setPortalType: (draft, action: PayloadAction<portalType>) => {
            const { payload } = action;
            draft.portalType = payload;
        },
        setPortalOrganizationId: (draft, action: PayloadAction<number>) => {
            const { payload } = action;
            draft.portalOrganizationId = payload;
        },
    },
});

export const { reducer: portalReducer } = portalSlice;
export const { setPortalId, setPortalOrganizationId, setPortalType } = portalSlice.actions;

export type IPortalState = ReturnType<typeof portalReducer>;
