import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {};

const sizes = {
    xl: {
        fontWeight: 300,
        fontSize: 48,
    },
    lg: {
        fontWeight: 400,
    },
    md: {
        fontWeight: 700,
    },
    sm: {},
};

const defaultProps = {
    size: 'lg',
};

export const Heading = {
    baseStyle,
    sizes,
    defaultProps,
};
