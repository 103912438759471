import moment, { LongDateFormatKey, LongDateFormatSpec } from 'moment';

export function updateLocale() {
    const symbolMap = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '0': '0',
    };
    const numberMap = {
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
        '٠': '0',
    };
    const getLocaleLongDateFormats: LongDateFormatSpec = (() => {
        const longDateFormatKeys: Array<LongDateFormatKey> = ['LTS', 'LT', 'L', 'LL', 'LLL', 'LLLL'];
        return Object.fromEntries(longDateFormatKeys.map((key) => [[key], moment.localeData().longDateFormat(key)]));
    })();

    moment.updateLocale('ar', {
        longDateFormat: {
            ...getLocaleLongDateFormats,
            L: 'YYYY/M/D',
        },
        preparse: (string: string) => {
            return string
                .replace(/\u200f/g, '')
                .replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => {
                    return numberMap[match];
                })
                .replace(/،/g, ',');
        },
        postformat: (string: string) => {
            return string
                .replace(/\d/g, (match) => {
                    return symbolMap[match];
                })
                .replace(/,/g, '،');
        },
    });
}
