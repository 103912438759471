import moment from 'moment';
// Provider
import { refreshToken } from '../providers/login.provider';

export interface ITokenValidatorOptions {
    skipTokenValidator?: boolean;
}

export function checkAccessTokenExpired() {
    const tokenExpiration = moment(sessionStorage.getItem('tokenExp'));
    const currentTimestamp = moment();
    return currentTimestamp > tokenExpiration;
}

export class TokenRefresherQueueMiddleware {
    refreshing = false;
    resolveQueue: any = [];

    check = (options: ITokenValidatorOptions = {}) => {
        return new Promise<void>((resolve) => {
            if (checkAccessTokenExpired() && (!options || !options.skipTokenValidator)) {
                this.resolveQueue.push(resolve);
                if (!this.refreshing) {
                    this.toggleRefreshingState();
                    refreshToken().finally(this.processQueue);
                }
            } else {
                resolve();
            }
        });
    };

    processQueue = () => {
        this.toggleRefreshingState();
        while (this.resolveQueue.length) {
            this.resolveQueue.pop()(); // Removing queue item and executing resolve.
        }
    };

    toggleRefreshingState = () => {
        this.refreshing = !this.refreshing;
    };
}
