export const parseQueryString = (queryString: string): any => {
    return new Proxy(new URLSearchParams(queryString), {
        get: (searchParams, prop) => searchParams.get(prop.toString()),
    });
};

export const stringify = (values: Record<string, any>): string => {
    if (!values) {
        return '';
    }
    return new URLSearchParams(values).toString();
};
