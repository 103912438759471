import React from 'react';
import { FormattedMessage } from 'react-intl';
// Components
import Button from '../Button';

// Using how-to
// <FilePicker maxSize={200} multiple={true} onPicked={this.onFilesPicked} accept={["jpg", "png"]}/>
//
// Returns pickedResult - {files: FileList, errors: Array<errorCodes>} as params on onPicked props callback function.
//

interface IFilePickerProps {
    inputId?: string;
    buttonId?: string;
    label: string;
    className?: string;
    multiple?: boolean;
    accept?: Array<string>;
    maxSize?: number;
    maxCount?: number;
    disabled?: boolean;
    isOpenedFromOutside?: boolean;
    onPicked: (files: IPickedResult) => void;
}

export enum errorCodes {
    FILE_TYPES_INVALID,
    FILE_SIZE_INVALID,
    FILE_COUNT_INVALID,
}

export interface IFileResult {
    file: File;
    errors: Array<errorCodes>;
}

export interface IPickedResult {
    files: Array<IFileResult>;
    commonErrors: Array<errorCodes>;
}

class FilePicker extends React.Component<IFilePickerProps> {
    private fileInput = React.createRef<HTMLInputElement>();

    componentDidUpdate(prevProps: IFilePickerProps) {
        if (!prevProps.isOpenedFromOutside && this.props.isOpenedFromOutside) {
            this.triggerInputFile();
        }
    }

    triggerInputFile = () => {
        this.fileInput.current.click();
    };

    validateSelectedFileType = (file: File) => {
        return this.props.accept.indexOf(file.name.split('.').pop().toLowerCase()) < 0;
    };

    validateSelectedFileSize = (file: File) => {
        return file.size > this.props.maxSize * 1024;
    };

    validateFile = (file: File) => {
        const errors = [];
        if (this.props.accept && this.validateSelectedFileType(file)) {
            errors.push(errorCodes.FILE_TYPES_INVALID);
        }

        if (this.props.maxSize && this.validateSelectedFileSize(file)) {
            errors.push(errorCodes.FILE_SIZE_INVALID);
        }
        return errors;
    };

    prepareFileResults = (files: FileList) => {
        return Array.from(files).map((file) => {
            return {
                file,
                errors: this.validateFile(file),
            };
        });
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const commonErrors = [];

        if (this.props.maxCount && e.target.files.length > this.props.maxCount) {
            commonErrors.push(errorCodes.FILE_COUNT_INVALID);
        }

        this.props.onPicked({
            files: this.prepareFileResults(e.target.files),
            commonErrors: commonErrors,
        });

        this.fileInput.current.value = null;
    };

    generateAcceptableFileTypes = () => {
        if (this.props.accept) {
            return this.props.accept.map((type) => `.${type}`).join(',');
        } else {
            return '*';
        }
    };

    public render() {
        return (
            <>
                <label htmlFor={this.props.label} className="hidden">
                    <FormattedMessage id="UPLOAD_FILES" />
                </label>
                <input
                    id={this.props.inputId ? this.props.inputId : this.props.label}
                    className="d-none"
                    ref={this.fileInput}
                    accept={this.generateAcceptableFileTypes()}
                    multiple={this.props.multiple || false}
                    onChange={this.onChange}
                    type="file"
                />
                <Button
                    type="button"
                    id={this.props.buttonId ? this.props.buttonId : this.props.label}
                    className={this.props.className}
                    disabled={this.props.disabled}
                    onClick={this.triggerInputFile}
                >
                    {this.props.label}
                </Button>
            </>
        );
    }
}

export default FilePicker;
