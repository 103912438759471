export enum MailboxAccessRoles {
    OWNER = 1,
    READWRITE = 2,
    READONLY = 3,
}

export enum AccessStatus {
    PENDING = 1,
    ACCEPTED = 2,
    DECLINED = 3,
    DELETED = 4,
    EXPIRED = 5,
}
