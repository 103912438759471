import store from '../redux/store';
import { setRequestState } from '../redux/appreadouts';
import { IValidateError, IError, ITranslateKeyError } from '../interfaces/general.interfaces';
import { errorCodes, getTranslateErrorKey } from '../helpers/errors';
import { staticConfig } from '../config/static';
import { IErrorCodeOptions } from './interfaces';
import { isResponseStatusHandled, redirectToSystemError } from './helpers';
const settings = {
    apiBaseUrl: staticConfig.apiBaseUrl,
};

export function typedRequest<T>(
    url: string,
    options?: object,
    errorCodeOptions?: IErrorCodeOptions,
    getPaginationFromHeaders?: boolean
): Promise<T> {
    return new Promise((resolve, reject) => {
        store.dispatch(setRequestState(true));
        fetch(settings.apiBaseUrl + url, options)
            .then((response) => {
                const responseStatusHandled = isResponseStatusHandled(response, errorCodeOptions);
                if (!response.ok && ![400, 404].includes(response.status) && !responseStatusHandled) {
                    redirectToSystemError(response, null);
                    reject();
                }
                response.text().then((rawResponse) => {
                    let resBody;
                    let paginationResponseHeaders;

                    try {
                        resBody = JSON.parse(rawResponse);

                        if (getPaginationFromHeaders) {
                            paginationResponseHeaders = {
                                page: Number(response.headers.get('Pagination-Page')),
                                pageSize: Number(response.headers.get('Pagination-PageSize')),
                                totalItems: Number(response.headers.get('Pagination-Total')),
                            };
                            resBody = {
                                items: JSON.parse(rawResponse),
                                ...paginationResponseHeaders,
                            };
                        }
                    } catch (e) {
                        resBody = rawResponse;
                    }
                    if ((resBody.validationErrors && resBody.validationErrors.length > 0) || resBody.errorCode) {
                        const errorList: Array<IError | ITranslateKeyError> = [];

                        if (resBody.validationErrors) {
                            resBody.validationErrors.forEach((error: IValidateError) => {
                                if (error.validationErrorCode) {
                                    if (responseStatusHandled) {
                                        errorList.push({
                                            errorCode: error.validationErrorCode,
                                            propertyName: error.propertyName,
                                        });
                                    } else {
                                        if (!errorCodes[error.validationErrorCode]) {
                                            redirectToSystemError(response, error.validationErrorCode);
                                            reject();
                                        }
                                        errorList.push(
                                            getTranslateErrorKey(
                                                error.validationErrorCode,
                                                error.propertyName,
                                                errorCodeOptions && errorCodeOptions.errorCodeComponentContext
                                            )
                                        );
                                    }
                                }
                            });
                        } else if (resBody.errorCode) {
                            if (responseStatusHandled) {
                                errorList.push({ errorCode: resBody.errorCode, propertyName: resBody.propertyName });
                            } else {
                                if (!errorCodes[resBody.errorCode]) {
                                    redirectToSystemError(response, resBody.errorCode);
                                    reject();
                                }
                                errorList.push(
                                    getTranslateErrorKey(
                                        resBody.errorCode,
                                        resBody.propertyName,
                                        errorCodeOptions && errorCodeOptions.errorCodeComponentContext
                                    )
                                );
                            }
                        }
                        reject(errorList);
                    } else if (resBody.error_description) {
                        reject(resBody);
                    } else {
                        if (response.ok) {
                            resolve(resBody);
                        } else {
                            if (!responseStatusHandled) {
                                redirectToSystemError(response, null);
                            }
                            reject({
                                status: response.status,
                                body: resBody,
                            });
                        }
                    }
                });
            })
            .finally(() => {
                store.dispatch(setRequestState(false));
            });
    });
}
