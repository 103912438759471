import type { SystemStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode, transparentize } from '@chakra-ui/theme-tools';

const parts = ['container', 'item', 'section', 'title'];

const baseStyleContainer: SystemStyleObject = {
    height: 'full',
    transitionProperty: 'all',
    transitionDuration: 'normal',
};

const baseStyleItem: SystemStyleObject = {
    w: 'full',
    flexShrink: 0,
    textAlign: 'left',
    display: 'inline-flex',
    alignItems: 'center',
    transitionProperty: 'all',
    transitionDuration: 'normal',
    _hover: {
        textDecoration: 'none',
        color: 'inherit',
    },
};

const baseSyleSection: SystemStyleObject = {
    '.navigation-section-button': {
        ...baseStyleItem,
    },
};

const baseStyleTitle: SystemStyleObject = {
    fontWeight: 'semibold',
    fontSize: 'sm',
    px: '8',
    py: '2',
};

const baseStyle = {
    container: baseStyleContainer,
    section: baseSyleSection,
    item: baseStyleItem,
    title: baseStyleTitle,
};

const variantAdminPart: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;

    const bgHoverDark = transparentize(`${c}.300`, 0.12)(props);

    return {
        _hover: {
            bg: mode(`${c}.50`, bgHoverDark)(props),
        },
        '.chakra-icon': {
            color: mode(`${c}.600`, `${c}.300`)(props),
        },
    };
};

const variantAdminPortal: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;

    return {
        section: {
            '&[data-state="expanded"]': {
                bg: mode('gray.50', 'gray.700')(props),
                '.navigation-section-button': {
                    borderStartWidth: '4px',
                    borderColor: mode(`${c}.600`, `${c}.300`)(props),
                },
            },
            '.navigation-section-button': {
                ...variantAdminPart(props),
            },
        },
        item: {
            h: '14',
            px: '5',
            fontWeight: 'semibold',
            ...variantAdminPart(props),
            '&[data-state="active"]': {
                bg: mode('gray.100', 'gray.700')(props),
            },
        },
        container: {
            borderColor: mode('gray.200', 'gray.700')(props),
            borderEndWidth: '1px',
        },
    };
};

const variantUserPortal: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;

    return {
        title: {
            color: mode('gray.400', 'whiteAlpha.400')(props),
        },
        item: {
            h: '10',
            px: '8',
            fontWeight: 'normal',
            _hover: {
                bg: mode('gray.50', 'gray.700')(props),
            },
            '&[data-state="active"]': {
                textDecoration: 'underline',
            },
            '.chakra-icon': {
                fontSize: '2xl',
                color: mode(`${c}.600`, `${c}.300`)(props),
            },
        },
        container: {
            zIndex: 'docked',
            bg: mode('white', 'whiteAlpha.600')(props),
            borderColor: mode('gray.100', 'gray.700')(props),
            borderEndWidth: '1px',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            display: 'flex',
            h: 'full',
        },
    };
};

const variants = {
    square: variantAdminPortal,
    soft: variantUserPortal,
};

const defaultProps = {
    size: 'md',
    variant: 'square',
    colorScheme: 'red',
};

export const Navigation = {
    parts,
    variants,
    baseStyle,
    defaultProps,
};
