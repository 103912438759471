import { ISender } from 'src/interfaces';
import { staticConfig } from '../config/static';

/**
 * get the URL of en image from e-bokses assets
 */
export const getImageUrl = (id?: number): string | undefined => {
    if (!id) {
        return undefined;
    }
    const imageUrl = `${staticConfig.apiBaseUrl}/staticresourceservice/v1/images/`;
    return imageUrl + id;
};

export const getSenderImage = (sender: ISender): string =>
    sender.logoId ? `${staticConfig.apiBaseUrl}/staticresourceservice/v1/images/${sender.logoId}` : undefined;
