import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import type {
    PartsStyleInterpolation,
    PartsStyleObject,
    SystemStyleObject,
    SystemStyleFunction,
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleContainer: SystemStyleObject = {
    fontWeight: 'medium',
    outline: 0,
    _focus: {
        boxShadow: 'outline',
    },
};

const baseStyleLabel: SystemStyleObject = {
    overflow: 'visible',
};

const baseStyleCloseButton: SystemStyleObject = {
    fontSize: 'lg',
    w: 5,
    h: 5,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    borderRadius: 'full',
    marginStart: '0.375rem',
    marginEnd: '-1',
    opacity: 0.5,
    _disabled: {
        opacity: 0.4,
    },
    _focus: {
        boxShadow: 'outline',
        bg: 'blackAlpha.300',
    },
    _hover: { opacity: 0.8 },
    _active: { opacity: 1 },
};

const baseStyle: PartsStyleObject<typeof parts> = {
    container: baseStyleContainer,
    label: baseStyleLabel,
    closeButton: baseStyleCloseButton,
};

const variantSolid: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        borderRadius: 'full',
        borderWidth: '1px',
        borderColor: mode(`${c}.200`, `${c}.700`)(props),
        bg: mode(`${c}.50`, `${c}.800`)(props),
        color: mode(`${c}.800`, `${c}.50`)(props),
    };
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    sm: {
        container: {
            minH: 5,
            minW: 5,
            fontSize: 'xs',
            px: 2,
        },
        closeButton: {
            marginEnd: '-2px',
            marginStart: '0.1rem',
        },
    },
    md: {
        container: {
            minH: 6,
            minW: 6,
            fontSize: 'sm',
            px: 2,
        },
    },
    lg: {
        container: {
            minH: 8,
            minW: 8,
            fontSize: 'md',
            borderRadius: 'full',
            px: 3,
        },
    },
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
    solid: (props) => ({
        container: variantSolid(props),
    }),
};

const defaultProps = {
    size: 'md',
    variant: 'solid',
    colorScheme: 'gray',
};

export const Tag = {
    parts: parts.keys,
    variants,
    baseStyle,
    sizes,
    defaultProps,
};
