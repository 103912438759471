import React from 'react';
import { createContext } from '@chakra-ui/react-utils';
import {
    HTMLChakraProps,
    forwardRef,
    StylesProvider,
    useMultiStyleConfig,
    CSSObject,
    Flex,
    ThemingProps,
} from '@chakra-ui/react';

type NavigationContext = {
    isCollapsed: boolean;
};

export const [NavigationProvider, useNavigationContext] = createContext<NavigationContext>({
    name: 'NavigationContext',
    errorMessage: 'useNavigationContext: context is undefined',
});

interface NavigationProps extends HTMLChakraProps<'div'>, ThemingProps {
    title?: string;
    isCollapsed?: boolean;
}

/**
 * The Navigation provides a sidebar layout used to contain navigational actions,
 * such as routing links.
 *
 * @link https://www.figma.com/file/O4VR1HcbsIRTIiNKLTbsM7/?node-id=7906%3A109832
 */
export const Navigation = forwardRef<NavigationProps, 'div'>(({ children, isCollapsed, variant, ...props }, ref) => {
    const styles = useMultiStyleConfig('Navigation', { variant });

    const positionStyles: CSSObject = {
        width: !isCollapsed ? '72' : '20',
        ...styles.container,
    };

    return (
        <StylesProvider value={styles}>
            <NavigationProvider value={{ isCollapsed }}>
                <Flex __css={positionStyles} ref={ref} {...props}>
                    {children}
                </Flex>
            </NavigationProvider>
        </StylesProvider>
    );
});
