import {
    ICreateUser,
    IUpdateUser,
    IUserInfo,
    IUpdateUserStatus,
    IActivatePassword,
    IUserCertificateBase64,
    IUserData,
    IUserStatus,
    IResetPassword,
    IChangePassword,
    IUserSearch,
    IFoundUser,
} from '../interfaces/user.interfaces';
import * as api from '../middleware/api.middleware';
// Interfaces
import { IIdentifier, IResponseCollection } from '../interfaces/general.interfaces';
// Helpers
import { getMiddlewareOptionsWithContext } from '../middleware/helpers';
// Enums
import { deactivationReasonEnum } from '../enums/adminPortal';
import { ErrorContext } from '../enums/context';

const serviceUrl = '/userservice/v2';

export function createUser(data: ICreateUser) {
    return api.post<IIdentifier>(`${serviceUrl}/users`, data);
}

export function updateUser(data: IUpdateUser, userId?: number) {
    userId = userId || +sessionStorage.getItem('userId');
    return api.post<IIdentifier>(`${serviceUrl}/users/${userId}/update`, data);
}

export function getUserInfo(userId?: number) {
    userId = userId || +sessionStorage.getItem('userId');
    return api.get<IUserInfo>(`${serviceUrl}/users/${userId}`);
}

export function getUserData(userId?: number, context?: ErrorContext) {
    return api.get<IUserData>(`${serviceUrl}/users/${userId}`, null, getMiddlewareOptionsWithContext(context));
}

export function getUserStatus(userId?: number) {
    return api.get<IUserStatus>(`${serviceUrl}/user-status/${userId}`);
}

export function updateUserStatus(userId: number, active: boolean, deactivationReason?: deactivationReasonEnum) {
    const data: IUpdateUserStatus = { active: active };
    if (deactivationReason) data.deactivationReason = deactivationReason;
    return api.post<null>(`${serviceUrl}/user-status/${userId}/update`, data);
}

export function activatePassword(data: IActivatePassword) {
    return api.post<null>(`${serviceUrl}/password-credentials/activate-password`, data);
}

export function updateCertificate(data: IUserCertificateBase64, certificateId: number) {
    return api.post<null>(`${serviceUrl}/certificates/${certificateId}/update`, { data: data.base64 });
}

export function resetPassword(data: IResetPassword) {
    return api.post<null>(`${serviceUrl}/password-credentials/reset-password`, data);
}

export function changePassword(data: IChangePassword) {
    return api.post<null>(`${serviceUrl}/password-credentials/change-password`, data);
}

export function findUser(data: IUserSearch) {
    return api.post<IFoundUser[]>(`${serviceUrl}/users/search`, data);
}

export function requestDeletion(userId: number) {
    return api.post<null>(`${serviceUrl}/user-status/${userId}/request-deletion`, null);
}

export function deliverMessages(accept: boolean, userId: number) {
    return api.post<null>(`${serviceUrl}/users/interim/deliver-messages`, { accept, userId });
}

export function getUserByInternalReferenceNumber(internalReferenceNumber: string) {
    return api.get<IResponseCollection<object>>(
        `${serviceUrl}/users?$fields=internalReferenceNumber&internalReferenceNumber=${internalReferenceNumber}`
    );
}
