import type { ThemeConfig } from '@chakra-ui/react';
import { foundations } from './foundation';
import * as components from './components';

const config: ThemeConfig = {
    useSystemColorMode: false,
    initialColorMode: 'light',
    cssVarPrefix: 'chakra',
};

export const theme = {
    ...foundations,
    components: {
        ...components,
    },
    config,
};

export type Theme = typeof theme;
