export enum dateTimeTemplate {
    FULL_TIME = 'LTS',
    FULL_DATE = 'LL',
    ONLY_DATE = 'L',
    ONLY_TIME = 'LT',
    ONLY_YEAR = 'YYYY',
    ONLY_MONTH = 'MMM YYYY',
    ONLY_WEEK = 'W GGGG',
    SHORT_WEEKDAY = 'ddd',
    SHORT_FULL_DATE = 'll',
    MONTH_DATE_TIME = 'LLL',
    SHORT_MONTH_DATE_TIME = 'lll',
}

export enum dateTimeFormat {
    DATE_AND_TIME = 'DATE_AND_TIME',
    DATE_AND_FULL_TIME = 'DATE_AND_FULL_TIME',
    FULL_DATE_AND_TIME = 'FULL_DATE_AND_TIME',
    FULL_DATE_AND_TIME_NUMERIC = 'FULL_DATE_AND_TIME_NUMERIC',
    ONLY_DATE = 'ONLY_DATE',
    ONLY_TIME = 'ONLY_TIME',
    ONLY_YEAR = 'ONLY_YEAR',
    ONLY_MONTH = 'ONLY_MONTH',
    ONLY_WEEK = 'ONLY_WEEK',
    ONLY_DAY = 'ONLY_DAY',
    ONLY_HOUR = 'ONLY_HOUR',
    SHORT_FULL_DATE = 'SHORT_FULL_DATE',
    MONTH_DATE_TIME = 'MONTH_DATE_TIME',
    SHORT_MONTH_DATE_TIME = 'SHORT_MONTH_DATE_TIME',
}

export enum languageLocaleMapper {
    'en' = 'en-ie',
    'da' = 'da',
    'ga' = 'ga',
    'ar' = 'ar',
}

export enum languageDateTimeFormatSeparatorOverride {
    'da' = '-',
}

export enum timeUnits {
    HOUR = 'hours',
    DAY = 'days',
    WEEK = 'weeks',
    MONTH = 'months',
    YEAR = 'years',
}

export enum dateRanges {
    DISABLED_FUTURE_DATES = 'DISABLED_FUTURE_DATES',
    DISABLED_PAST_DATES = 'DISABLED_PAST_DATES',
    DISABLED_TODAY_AND_FUTURE_DATES = 'DISABLED_TODAY_AND_FUTURE_DATES',
    DISABLED_TODAY_AND_PAST_DATES = 'DISABLED_TODAY_AND_PAST_DATES',
}

export enum dateTimeLocalFormat {
    default = 'YYYY-MM-DDTHH:mm',
    date = 'YYYY-MM-DD',
    time = 'HH:mm',
}

export enum dateTimeDisplayFormat {
    default = 'MMM Do, YYYY HH:mm:ss',
    date = 'MMM Do, YYYY',
}
