export enum portalType {
    AdminPortal = 'AdminPortal',
    UserPortal = 'UserPortal',
    OrganizationPortal = 'OrganizationPortal',
}

export enum hybridClientIds {
    AdminPortal = 'admin.hybrid.client',
    UserPortal = 'user.hybrid.client',
    OrganizationPortal = 'user.hybrid.client',
}

export enum portalFeatureToggle {
    INTERIM_REPOSITORY,
    SIGNING,
    OPENING_RECEIPTS,
    MANDATORY_SENDERS,
}
